import React, { DragEvent, useState } from 'react';
import { Alert, App, Button, Space, Table, Upload } from 'antd';
import { MainContent } from '../components/MainContent';
import { CloudDownloadOutlined, CloudUploadOutlined, LoadingOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-components';
import { ColumnsType } from 'antd/es/table';
import { css } from '@emotion/css';
import { useAuth } from 'react-oidc-context';
import { useCurrentContextStore } from '../hooks/store/useCurrentContextStore.ts';
import { useNavigate } from 'react-router';

interface Message {
  readonly id: string;
  readonly type: string;
  readonly row: number;
  readonly text: string;
}

interface CsvImportResponse {
  readonly successCount: number;
  readonly messages: Message[];
}

const { Dragger } = Upload;
const allowedMimeTypes = ['text/csv'];

export const CsvImport: React.FC = () => {
  const { currentDoctorId } = useCurrentContextStore();
  const [uploading, setUploading] = useState(false);
  const [result, setResult] = useState<CsvImportResponse | null>(null);
  const auth = useAuth();
  const { message } = App.useApp();
  const navigate = useNavigate();

  const onDrop = (event: DragEvent) => {
    if (Array.from(event.dataTransfer.files).some(f => !allowedMimeTypes.includes(f.type))) {
      message.error('Nur CSV-Dateien sind erlaubt');
    }
  };

  const beforeUpload = (file: File) => {
    const mimeTypeOk = allowedMimeTypes.find(mimeType => mimeType === file.type);
    if (!mimeTypeOk) {
      message.error('Nur CSV-Dateien sind erlaubt');
    }
    const fileSizeOk = file.size / 1024 / 1024 < 5;
    if (!fileSizeOk) {
      message.error('Datei muss kleiner als 5 MB sein');
    }
    if (mimeTypeOk && fileSizeOk) {
      upload(file);
    }
    return false;
  };

  const upload = async (file: File) => {
    setUploading(true);
    const endpoint = window._env_.API_URL + '/rest/csv-import';
    const formData = new FormData();
    formData.append('file', file);
    formData.append('doctorId', currentDoctorId);

    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        body: formData,
        headers: { authorization: `Bearer ${auth.user?.access_token}` },
      });
      const result = await response.json();
      setResult(result);

      if (!response.ok) {
        message.error('Import fehlgeschlagen');
        setResult(null);
      }
    } catch {
      message.error('Import fehlgeschlagen');
      setResult(null);
    } finally {
      setUploading(false);
    }
  };

  const columns: ColumnsType<Message> = [
    {
      title: 'Zeile',
      dataIndex: 'row',
      key: 'row',
      width: 150,
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.row - b.row,
    },
    {
      title: 'Typ',
      dataIndex: 'type',
      key: 'type',
      width: 150,
      sorter: (a, b) => a.type.localeCompare(b.type),
      render: v => (v === 'FAILURE' ? 'Fehler' : 'Info'),
    },
    {
      title: 'Nachricht',
      dataIndex: 'text',
      key: 'text',
      sorter: (a, b) => a.text.localeCompare(b.text),
    },
  ];

  return (
    <MainContent>
      <PageHeader
        title="CSV-Import"
        style={{ padding: 0, paddingBottom: 'inherit' }}
        onBack={() => navigate('/offene-anforderungen')}
        extra={
          <a download href="/csv-import-beispiel.csv">
            <Button icon={<CloudDownloadOutlined />}>Beispiel CSV-Datei</Button>
          </a>
        }
      />

      <Dragger disabled={uploading} showUploadList={false} beforeUpload={beforeUpload} accept=".csv">
        <div
          onDrop={onDrop}
          style={{
            display: 'flex',
            height: '150px',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <p className="ant-upload-drag-icon">{uploading ? <LoadingOutlined /> : <CloudUploadOutlined />}</p>
          <p className="ant-upload-text">
            Anforderungen für den <b>ausgewählten Zuweiser</b> importieren
          </p>
          <p className="ant-upload-hint">
            Klicken Sie hier, oder ziehen Sie die Datei in diesen Bereich, um den Vorgang zu starten. Erlaubter
            Dateityp: CSV
          </p>
        </div>
      </Dragger>
      <p />
      {result && (
        <Space
          direction="vertical"
          className={css`
            width: 100%;
          `}
        >
          <Alert type="success" showIcon message={`${result.successCount} Anforderungen erfolgreich verarbeitet`} />
          {result.messages.length > 0 && (
            <>
              <h3
                className={css`
                  margin: 0;
                `}
              >
                Meldungen
              </h3>
              <Table<Message>
                scroll={{ x: 'max-content' }}
                rowKey={record => record.id}
                size="small"
                showSorterTooltip={false}
                dataSource={result?.messages ?? []}
                pagination={{
                  showQuickJumper: true,
                  showSizeChanger: true,
                  showTotal: (total, range) => `${range[0]} bis ${range[1]} von ${total} Meldungen`,
                }}
                columns={columns}
              />
            </>
          )}
        </Space>
      )}
    </MainContent>
  );
};
