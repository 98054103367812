import React from 'react';
import { Badge, Flex, theme } from 'antd';
import { css } from '@emotion/css';
import { TestTubeColorProperties } from '../utils/enumHelpers.ts';

const { useToken } = theme;

export const TestTubeColor: React.FC<{
  properties: TestTubeColorProperties;
  withText?: boolean;
}> = ({ properties, withText = true }) => {
  const { token } = useToken();
  return (
    <Flex
      gap={token.marginXXS}
      align="center"
      className={css`
        white-space: nowrap;
      `}
    >
      <Badge
        className={css`
          & .ant-badge-status-dot {
            width: 10px !important;
            height: 10px !important;
            display: flex !important;
            align-items: center !important;
            top: 0 !important;
          }
        `}
        color={properties.colorCode}
        dot
      />
      {withText ? properties.translation : ''}
    </Flex>
  );
};
