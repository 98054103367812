import React, { useEffect, useState } from 'react';
import 'dayjs/locale/de-at';
import './styles/fonts/inter/400.css';
import './styles/fonts/inter/500.css';
import './styles/fonts/inter/600.css';
import { App as AntApp, Button, ConfigProvider, FloatButton, Layout, Result, Spin } from 'antd';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router';
import { ApolloProvider } from '@apollo/client';
import { apolloFactory } from './graphql/ApolloFactory';
import 'antd/dist/reset.css';
import './styles/global.css';
import { NoAccess } from './components/NoAccess';
import { AppHeader } from './components/AppHeader';
import { Dashboard } from './views/Dashboard';
import { SCROLLER } from './utils/elementIds';
import { datadogRum } from '@datadog/browser-rum';
import { ErrorBoundary } from 'react-error-boundary';
import { LoadingIndicator } from './components/LoadingIndicator';
import { hasAnyRelevantRole, Role } from './utils/user';
import { Users } from './views/admin/Users';
import { Doctors } from './views/admin/Doctors';
import { Insurances } from './views/admin/Insurances';
import { Labs } from './views/admin/Labs';
import { UserMenuWrapper } from './views/user/UserMenuWrapper';
import { Lab } from './views/admin/Lab';
import { ErrorFallback } from './components/ErrorFallback';
import { OfflineBlockingModal } from './components/OfflineBlockingModal';
import { NewRequest } from './views/NewRequest';
import { PatientRegistration } from './views/request/PatientRegistration';
import { ParameterSelection } from './views/request/ParameterSelection';
import { AppVersionCheckerModal } from './components/AppVersionCheckerModal';
import { AppVersionAutoUpdater } from './components/AppVersionAutoUpdater.tsx';
import { RequestSummary } from './views/request/RequestSummary';
import { OpenRequests } from './views/OpenRequests';
import { SentRequests } from './views/SentRequests';
import { ImportFileWatcher } from './components/ImportFileWatcher';
import { PrivateRoute } from './components/PrivateRoute';
import { Sidebar } from './components/Sidebar';
import { css } from '@emotion/css';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import { PatientSearch } from './views/PatientSearch';
import { QuickActions } from './views/quickactions/QuickActions';
import { AppStateResetter } from './components/AppStateResetter';
import { Profiles } from './views/settings/Profiles';
import { DbToolImport } from './views/admin/DbToolImport';
import { DynamicConfigProvider } from './DynamicConfigProvider.tsx';
import { Themes } from './views/admin/Themes.tsx';
import { defaultTheme, useThemeStore } from './hooks/store/useThemeStore.ts';
import { DOCTOR_THEME_QUERY } from './hooks/useDoctorTheme.ts';
import { Sheets } from './views/settings/Sheets.tsx';
import { graphql } from './graphql/generated';
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons';
import { useAuth } from 'react-oidc-context';
import { useAuthEvents } from './hooks/useAuthEvents';
import { SocketProvider } from './socket/SocketProvider';
import { useCurrentContextStore } from './hooks/store/useCurrentContextStore.ts';
import { SystemStatus } from './views/admin/SystemStatus.tsx';
import { SystemStatusWrapper } from './SystemStatusWrapper.tsx';
import { LabAdminLab } from './views/settings/lab-admin/LabAdminLab.tsx';
import { CsvImport } from './views/CsvImport.tsx';
import { userSettingsStoreDefaultName, useUserSettingsStore } from './hooks/store/useUserSettingsStore.ts';
import { Doctor } from './views/settings/Doctor.tsx';
import { DoctorSearch } from './views/DoctorSearch.tsx';
import { CreateFrontDeskDoctor } from './views/CreateFrontDeskDoctor.tsx';
import { FibuExport } from './views/FibuExport.tsx';
import { FrontDeskOverview } from './views/FrontDeskOverview.tsx';
import { OpenFrontDeskRequests } from './views/OpenFrontDeskRequests.tsx';
import { Ecard } from './views/settings/Ecard.tsx';

const LazySampleCollection = React.lazy(() => import('./views/SampleCollection.tsx'));
const { Content } = Layout;

dayjs.locale('de-at');
// plugins needed for production build
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

Spin.setDefaultIndicator(<LoadingOutlined />);

const { apolloClient } = apolloFactory();

export const ASSIGNMENT_FOR_CURRENT_USER_QUERY = graphql(`
  query AssignmentForCurrentUser {
    assignmentForCurrentUser {
      id
      primaryDoctor {
        id
      }
    }
  }
`);

export const USER_DOCTOR_AUTH_CHECK_QUERY = graphql(`
  query UserDoctorAuthCheckQuery($doctorId: ID!) {
    isCurrentUserAuthorizedForDoctor(doctorId: $doctorId)
  }
`);

export const App: React.FC = () => {
  const auth = useAuth();
  const [appInitialized, setAppInitialized] = useState(false);
  const [initError, setInitError] = useState(false);
  const { userId, setUserId, currentDoctorId, setCurrentDoctorId, setPrimaryDoctorId } = useCurrentContextStore();
  const { theme, setTheme } = useThemeStore();

  useAuthEvents();

  useEffect(() => {
    // reset desktop user info
    window.nativeApi?.setUserInfo(null);
  }, []);

  useEffect(() => {
    if (!auth.isAuthenticated || appInitialized) {
      return;
    }

    datadogRum.setUser({
      id: auth.user?.profile.sub,
      name: auth.user?.profile.preferred_username,
      email: auth.user?.profile.email,
    });
    datadogRum.startSessionReplayRecording();

    const initialize = async () => {
      try {
        const { data, error } = await apolloClient.query({
          query: ASSIGNMENT_FOR_CURRENT_USER_QUERY,
        });
        const primaryDoctorId = data.assignmentForCurrentUser?.primaryDoctor?.id ?? '';

        if (error) {
          setInitError(true);
        } else {
          let doctorId = '';
          if (
            currentDoctorId &&
            userId === auth.user?.profile.sub // from sessionStorage
          ) {
            const { data: result } = await apolloClient.query({
              query: USER_DOCTOR_AUTH_CHECK_QUERY,
              variables: { doctorId: currentDoctorId },
            });
            if (result.isCurrentUserAuthorizedForDoctor) {
              doctorId = currentDoctorId;
            } else {
              doctorId = primaryDoctorId;
            }
          } else {
            doctorId = primaryDoctorId;
          }

          setPrimaryDoctorId(primaryDoctorId);
          setCurrentDoctorId(doctorId);
          setUserId(auth.user?.profile.sub ?? '');

          useUserSettingsStore.persist.setOptions({
            name: `${userSettingsStoreDefaultName}_${auth.user?.profile.sub}`,
          });
          useUserSettingsStore.persist.rehydrate();
          localStorage.removeItem(userSettingsStoreDefaultName);

          // now load theme if doctor and role is available
          if (doctorId && hasAnyRelevantRole(auth.user)) {
            const { data } = await apolloClient.query({
              query: DOCTOR_THEME_QUERY,
              variables: {
                doctorId: doctorId,
              },
            });
            if (data.doctor?.theme) {
              setTheme(data.doctor.theme, false);
            }
          }
        }
      } catch {
        setInitError(true);
      } finally {
        setAppInitialized(true);
      }
    };
    initialize();
  }, [auth, userId, setUserId, currentDoctorId, setCurrentDoctorId, setPrimaryDoctorId, setTheme, appInitialized]);

  const styles = {
    layout: css`
      min-height: 100vh;
    `,
    mainContent: css`
      height: calc(100vh - 64px);

      .ant-layout-content,
      .ant-layout-sider-children {
        overflow-y: auto;
      }
    `,
  };

  if (auth.isLoading) {
    return (
      <ConfigProvider
        theme={{
          cssVar: true,
          token: {
            colorPrimary: defaultTheme.colorPrimary,
          },
        }}
      >
        <LoadingIndicator height="100vh" />
      </ConfigProvider>
    );
  }

  if (!appInitialized && auth.error) {
    return (
      <ConfigProvider
        theme={{
          cssVar: true,
          token: {
            colorPrimary: defaultTheme.colorPrimary,
          },
        }}
      >
        <Result
          status={500}
          title="Authentifizierung fehlgeschlagen"
          subTitle={auth.error.message}
          extra={
            <Button icon={<ReloadOutlined />} onClick={() => (window.location.href = '/')} type="primary">
              Applikation neu laden
            </Button>
          }
        />
      </ConfigProvider>
    );
  }

  return (
    <ApolloProvider client={apolloClient}>
      <DynamicConfigProvider>
        <AntApp>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            {!appInitialized && <LoadingIndicator height="100vh" />}
            {appInitialized && initError && (
              <Result
                status={500}
                title="Beim Laden der Applikation ist ein Fehler aufgetreten"
                subTitle="Bitte versuchen Sie die Applikation neu zu laden."
                extra={
                  <Button icon={<ReloadOutlined />} onClick={() => (window.location.href = '/')} type="primary">
                    Applikation neu laden
                  </Button>
                }
              />
            )}
            {appInitialized && !initError && (
              <SocketProvider>
                <BrowserRouter>
                  <Layout className={styles.layout}>
                    <AppHeader userActions={<UserMenuWrapper />} topMenu={<QuickActions />} theme={theme} />
                    <Layout className={styles.mainContent}>
                      <Sidebar />
                      <Content id={SCROLLER}>
                        <SystemStatusWrapper>
                          <Routes>
                            <Route element={<PrivateRoute />}>
                              <Route path="/dashboard" element={<Dashboard />} />
                              <Route
                                path="/zuweiserauswahl"
                                element={
                                  <PrivateRoute roles={[Role.ROLE_LR_MEDCOM, Role.ROLE_LR_FRONT_DESK]}>
                                    <DoctorSearch />
                                  </PrivateRoute>
                                }
                              />
                              <Route
                                path="/zuweiserauswahl/create"
                                element={
                                  <PrivateRoute roles={[Role.ROLE_LR_MEDCOM, Role.ROLE_LR_FRONT_DESK]}>
                                    <CreateFrontDeskDoctor />
                                  </PrivateRoute>
                                }
                              />
                              <Route path="/anforderung" element={<NewRequest />} />
                              <Route path="/anforderung/patient-erfassung" element={<PatientRegistration />} />
                              <Route path="/anforderung/parameterauswahl" element={<ParameterSelection />} />
                              <Route path="/anforderung/uebersicht" element={<RequestSummary />} />
                              <Route path="/offene-anforderungen" element={<OpenRequests />} />
                              <Route path="/offene-anforderungen/csv-import" element={<CsvImport />} />
                              <Route path="/gesendete-anforderungen" element={<SentRequests />} />
                              <Route path="/patient-suche" element={<PatientSearch showNewButton={false} />} />
                              <Route
                                path="/pv-uebersicht"
                                element={
                                  <PrivateRoute roles={[Role.ROLE_LR_MEDCOM, Role.ROLE_LR_FRONT_DESK]}>
                                    <FrontDeskOverview />
                                  </PrivateRoute>
                                }
                              />
                              <Route
                                path="/probenannahme"
                                element={
                                  <PrivateRoute roles={[Role.ROLE_LR_MEDCOM, Role.ROLE_LR_LAB_STAFF]}>
                                    <React.Suspense fallback={<LoadingIndicator height="100%" />}>
                                      <LazySampleCollection />
                                    </React.Suspense>
                                  </PrivateRoute>
                                }
                              />
                              <Route
                                path="/alle-offenen-pv-anforderungen"
                                element={
                                  <PrivateRoute roles={[Role.ROLE_LR_MEDCOM, Role.ROLE_LR_FRONT_DESK]}>
                                    <OpenFrontDeskRequests />
                                  </PrivateRoute>
                                }
                              />
                              <Route
                                path="/fibu-export"
                                element={
                                  <PrivateRoute
                                    roles={[Role.ROLE_LR_MEDCOM, Role.ROLE_LR_LAB_ADMIN, Role.ROLE_LR_FRONT_DESK]}
                                  >
                                    <FibuExport />
                                  </PrivateRoute>
                                }
                              />
                              <Route
                                path="/einstellungen/zuweiser"
                                element={
                                  <PrivateRoute
                                    roles={[Role.ROLE_LR_MEDCOM, Role.ROLE_LR_LAB_ADMIN, Role.ROLE_LR_USER]}
                                  >
                                    <Doctor />
                                  </PrivateRoute>
                                }
                              />
                              <Route path="/einstellungen/profile" element={<Profiles />} />
                              <Route path="/einstellungen/sheets" element={<Sheets />} />
                              <Route path="/einstellungen/ecard" element={<Ecard />} />
                              <Route path="/einstellungen/labor/*">
                                <Route
                                  path="*"
                                  element={
                                    <PrivateRoute roles={[Role.ROLE_LR_MEDCOM, Role.ROLE_LR_LAB_ADMIN]}>
                                      <LabAdminLab />
                                    </PrivateRoute>
                                  }
                                />
                              </Route>
                            </Route>
                            <Route element={<PrivateRoute roles={[Role.ROLE_LR_MEDCOM]} />}>
                              <Route path="/admin/themes" element={<Themes />} />
                              <Route path="/admin/benutzer" element={<Users />} />
                              <Route path="/admin/zuweiser" element={<Doctors />} />
                              <Route path="/admin/krankenkassen" element={<Insurances />} />
                              <Route path="/admin/db-tool-import" element={<DbToolImport />} />
                              <Route path="/admin/system-status" element={<SystemStatus />} />
                              <Route path="/admin/labors" element={<Labs />} />
                              <Route path="/admin/labors/:id/*">
                                <Route path="*" element={<Lab />} />
                              </Route>
                            </Route>
                            <Route path="/no-access" element={<NoAccess />} />
                            <Route path="*" element={<Navigate replace to="/dashboard" />} />
                          </Routes>
                        </SystemStatusWrapper>
                      </Content>
                      <FloatButton.BackTop
                        target={() => document.getElementById(SCROLLER) as HTMLElement}
                        visibilityHeight={2500}
                      />
                      <AppStateResetter />
                      <AppVersionCheckerModal />
                      <AppVersionAutoUpdater />
                      <ImportFileWatcher />
                      <OfflineBlockingModal />
                    </Layout>
                  </Layout>
                </BrowserRouter>
              </SocketProvider>
            )}
          </ErrorBoundary>
        </AntApp>
      </DynamicConfigProvider>
    </ApolloProvider>
  );
};
