import React from 'react';
import { App, Button, Menu, Result, theme } from 'antd';
import { Link, Navigate, Route, Routes, useLocation, useNavigate, useParams } from 'react-router';
import {
  BarcodeOutlined,
  BgColorsOutlined,
  CrownOutlined,
  DatabaseOutlined,
  FileSearchOutlined,
  FileTextOutlined,
  FormOutlined,
  SolutionOutlined,
  BuildOutlined,
  TableOutlined,
  FileDoneOutlined,
  FieldTimeOutlined,
} from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Basedata } from './lab/Basedata';
import { Parameters } from './lab/Parameters';
import { Specimens } from './lab/Specimens';
import { TestTubes } from './lab/TestTubes';
import { SpecialRates } from './lab/SpecialRates';
import { Catalogs } from './lab/Catalogs';
import { Diagnoses } from './lab/Diagnoses';
import { Documents } from './lab/Documents';
import { Forms } from './lab/Forms';
import { Sheets } from './lab/Sheets';
import { PeriodicGroups } from './lab/PeriodicGroups.tsx';
import { PageHeader } from '@ant-design/pro-components';
import { css } from '@emotion/css';
import { graphql } from '../../graphql/generated';

const LAB_QUERY = graphql(`
  query Lab($id: ID!) {
    lab(id: $id) {
      id
      name
      shortName
      longName
    }
  }
`);

const COMPILE_PARAMS_MUTATION = graphql(`
  mutation CompileParams {
    compileParams
  }
`);

export const Lab: React.FC = () => {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const { message } = App.useApp();
  const { token } = theme.useToken();

  const levels = location.pathname.split('/');
  const level1 = levels[4];
  const level2 = levels[5];
  let selectedKey = level1;
  if (level2) {
    selectedKey = level1 + '/' + level2;
  }

  const { data, loading } = useQuery(LAB_QUERY, {
    variables: {
      id: params.id ?? '',
    },
    fetchPolicy: 'cache-and-network',
  });

  const [compileParamsMutation, { loading: compileParamsLoading }] = useMutation(COMPILE_PARAMS_MUTATION);

  const handleBack = () => navigate('/admin/labors');

  if (!loading && data?.lab == null) {
    return (
      <Result
        status={404}
        title="Labor nicht gefunden"
        subTitle={`Das Labor mit ID ${params.id} konnte nicht gefunden werden`}
        extra={
          <Button onClick={handleBack} type="primary">
            Zurück
          </Button>
        }
      />
    );
  }

  const styles = {
    wrapper: css`
      display: flex;
      flex-direction: column;
      height: 100%;
    `,
    pageHeader: css`
      border-bottom: 1px solid ${token.colorBorderSecondary};
      background-color: white !important;
      padding: ${token.paddingMD}px ${token.paddingLG}px 0 ${token.paddingLG}px;
    `,
    content: css`
      overflow-y: auto;
      height: 100%;
    `,
  };

  return (
    <div className={styles.wrapper}>
      <PageHeader
        title={`${data?.lab?.name} (${data?.lab?.shortName})`}
        subTitle={data?.lab?.longName}
        onBack={handleBack}
        extra={
          <Button
            icon={<BuildOutlined />}
            onClick={() => {
              compileParamsMutation();
              message.success(
                'Die Parameter aller Labors werden nun zusammengestellt. Es kann einige Sekunden dauern, bis die Änderungen sichtbar werden.'
              );
            }}
            loading={compileParamsLoading}
          >
            Parameter zusammenstellen
          </Button>
        }
        className={styles.pageHeader}
      >
        <Menu
          defaultSelectedKeys={['basisdaten']}
          selectedKeys={[selectedKey]}
          mode="horizontal"
          style={{ borderBottom: 0, backgroundColor: 'white' }}
          items={[
            {
              key: 'basisdaten',
              icon: <FileSearchOutlined />,
              label: <Link to="../basisdaten">Basisdaten</Link>,
            },
            {
              key: 'parameter',
              icon: <TableOutlined />,
              label: 'Parameter',
              children: [
                {
                  key: 'parameter/parameter',
                  icon: <TableOutlined />,
                  label: <Link to="../parameter/parameter">Alle Laborparameter</Link>,
                },
                {
                  key: 'parameter/kataloge',
                  icon: <DatabaseOutlined />,
                  label: <Link to="../parameter/kataloge">Parameter-Kataloge</Link>,
                },
                {
                  key: 'parameter/periodische-verrechnungsgruppen',
                  icon: <FieldTimeOutlined />,
                  label: <Link to="../parameter/periodische-verrechnungsgruppen">Periodische Verrechnungsgruppen</Link>,
                },
              ],
            },
            {
              key: 'material',
              icon: <BarcodeOutlined />,
              label: <Link to="../material">Material</Link>,
            },
            {
              key: 'probenbehaelter',
              icon: <BgColorsOutlined />,
              label: <Link to="../probenbehaelter">Probenbehälter</Link>,
            },
            {
              key: 'sondertarife',
              icon: <CrownOutlined />,
              label: <Link to="../sondertarife">Sondertarife</Link>,
            },
            {
              key: 'diagnosen',
              icon: <SolutionOutlined />,
              label: <Link to="../diagnosen">Diagnosen</Link>,
            },
            {
              key: 'dokumente',
              icon: <FileTextOutlined />,
              label: <Link to="../dokumente">Dokumente</Link>,
            },
            {
              key: 'formulare',
              icon: <FormOutlined />,
              label: <Link to="../formulare">Formulare</Link>,
            },
            {
              key: 'sheets',
              icon: <FileDoneOutlined />,
              label: <Link to="../sheets">Sheets</Link>,
            },
          ]}
        />
      </PageHeader>
      <div className={styles.content}>
        <Routes>
          <Route path="basisdaten" element={<Basedata />} />
          <Route path="parameter/parameter" element={<Parameters />} />
          <Route path="parameter/kataloge" element={<Catalogs />} />
          <Route path="parameter/periodische-verrechnungsgruppen" element={<PeriodicGroups />} />
          <Route path="material" element={<Specimens />} />
          <Route path="probenbehaelter" element={<TestTubes />} />
          <Route path="sondertarife" element={<SpecialRates />} />
          <Route path="diagnosen" element={<Diagnoses />} />
          <Route path="dokumente" element={<Documents />} />
          <Route path="formulare" element={<Forms />} />
          <Route path="sheets" element={<Sheets />} />
          <Route path="*" element={<Navigate replace to="../basisdaten" />} />
        </Routes>
      </div>
    </div>
  );
};
