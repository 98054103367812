import React from 'react';
import { MainContent } from '../../../components/MainContent';
import { useParams } from 'react-router';
import { SheetEditor } from '../../../components/sheet-editor/SheetEditor.tsx';
import { useLabSheets } from '../../../hooks/useLabSheets.ts';

export const Sheets: React.FC = () => {
  const { id: labId } = useParams<{ id: string }>();
  const { autoSave, loading, sheetData, labParameters } = useLabSheets(labId ?? '');

  return (
    <MainContent size="medium">
      <SheetEditor
        loading={loading}
        sheets={sheetData}
        onChange={autoSave}
        showLab={false}
        flipParamNames={false}
        parameters={labParameters}
      />
    </MainContent>
  );
};
