import { simpleMode } from '@codemirror/legacy-modes/mode/simple-mode';
import { EditorView } from '@codemirror/view';
import { tags } from '@lezer/highlight';
import { HighlightStyle, StreamLanguage, syntaxHighlighting } from '@codemirror/language';

export const theme = (
  borderColor: string,
  focusBorderColor: string,
  fontFamily: string = "'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace"
) =>
  EditorView.baseTheme({
    '&.cm-editor': {
      border: `1px solid ${borderColor}`,
      '&.cm-focused': {
        border: `1px solid ${focusBorderColor}`,
        outline: 'none',
        boxShadow: 'none',
      },
      '&.cm-focused .cm-selectionBackground, ::selection': {
        backgroundColor: '#d7d4f0',
        color: 'inherit',
      },
    },
    '.cm-scroller': {
      fontFamily: fontFamily,
      lineHeight: 1.6,
    },
    '.cm-selectionBackground': {
      background: '#d7d4f0',
    },
  });

export const highlightTags = syntaxHighlighting(
  HighlightStyle.define([
    { tag: tags.variableName, color: '#05a' },
    { tag: tags.invalid, color: '#ff0000', fontStyle: 'italic' },
    { tag: tags.comment, color: '#085' },
    { tag: tags.bracket, color: '#997' },
    { tag: tags.keyword, color: '#708' },
    { tag: tags.controlKeyword, color: '#666' },
  ])
);

export const hl7 = StreamLanguage.define(
  simpleMode({
    start: [
      {
        regex:
          /(?:ACC|TQ1|PRT|SPM|ADD|AL1|BHS|BLG|BTS|DG1|DSC|DSP|ERR|EVN|FHS|FT1|FTS|GT1|IN1|IN2|IN3|MFA|MFE|MFI|MRG|MSA|MSH|NCK|NK1|NPU|NSC|NST|NTE|OBR|OBX|ODS|ODT|OM1|OM2|OM3|OM4|OM5|OM6|ORC|PD1|PID|PR1|PRA|PV1|PV2|QRD|QRF|RQ1|RQD|RXA|RXC|RXD|RXE|RXG|RXO|RXR|STF|UB1|UB2|URD|URS|ZAL|ZBN|ZEI|ZLR|ZNI|ZPI|ZQA|ZV1)\b/,
        token: 'keyword',
      },
      {
        regex:
          /(?:ACK|ADR|ADT|ARD|BAR|DFT|DSR|MCF|MFD|MFK|MFN|MFR|NMD|NMQ|NMR|ORF|ORM|ORR|ORU|OSQ|PGR|QRY|RAR|RAS|RDE|RDR|RDS|RER|RGV|ROR|RRA|RRD|RRE|RRG|UDM|OML|OMS)\b/,
        token: 'controlKeyword',
      },
      {
        regex:
          /(?:A01|A02|A03|A04|A05|A06|A07|A08|A09|A10|A11|A12|A13|A14|A15|A16|A17|A18|A19|A20|A21|A22|A23|A24|A25|A26|A27|A28|A29|A30|A31|A32|A33|A34|A35|A36|A37|M01|M02|M03|O01|O02|P01|P02|P03|P04|Q01|Q02|Q03|Q05|R01|R02|R03|R04|O05)\b/,
        token: 'controlKeyword',
      },
      { regex: /[|^&~\\]+/, token: 'bracket' },
    ],
  })
);

export const zpl = StreamLanguage.define(
  simpleMode({
    start: [
      {
        regex: /%(?:PATIENT|PATIENTDOB|SVNR|DOCTOR|DOCTORID|TEXT|TEXT2|BARCODE|ID|PRETTYCODE|LAB|FEATURES|LOCATION)%/,
        token: 'variableName',
      },
      {
        regex: /%.*%/,
        token: 'invalid',
      },
      {
        regex: /(\d+)([^\w])/,
        token: 'comment',
      },
      { regex: /[~^\\]+/, token: 'bracket' },
    ],
  })
);
