import React, { useState } from 'react';
import { EcardSetupAssistant } from './ecard/EcardSetupAssistant.tsx';
import { EcardConfigs } from './ecard/EcardConfigs.tsx';
import { useDoctorEcardEnabled } from '../../hooks/useDoctorEcardEnabled.ts';
import { hasSomeRole, Role } from '../../utils/user.ts';
import { useAuth } from 'react-oidc-context';
import { useCurrentContextStore } from '../../hooks/store/useCurrentContextStore.ts';
import { Alert } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import { MainContent } from '../../components/MainContent.tsx';
import { LockOutlined } from '@ant-design/icons';

// # 4. lesegerät auswählen und adresse speichern
// # 5. karte stecken und PIN angeben -> check
// # 6. dialogdata holen und ordinationen anzeigen und auswählen => bei test vergleichen ob match
// # 7. taetigkeitsberech, ordiId auswählen
// # 8. daten beim zuweiser speichern
//
// # berechtigungen für Sas checken SAS.CORE => bei test anzeigen
// # API erweitern und in labrador rest client erzeugen lassen und controller erzeugen lassen -> file kopieren
// brauchen wir irgendwo die VPNR gespeichert?
// retry bei abfragen, wo dialog benötigt wird: cached dialog -> request -> clear cache -> neuer dialog -> request

export const Ecard: React.FC = () => {
  const [showAssistant, setShowAssistant] = useState(false);
  const auth = useAuth();
  const isUserRole = hasSomeRole([Role.ROLE_LR_USER], auth.user);
  const { currentDoctorId, primaryDoctorId } = useCurrentContextStore();
  const doctorId = isUserRole ? currentDoctorId : primaryDoctorId;
  const ecardEnabled = useDoctorEcardEnabled(doctorId);

  if (!ecardEnabled) {
    return (
      <MainContent size="medium">
        <PageHeader title="e-card Einstellungen" />
        <Alert
          type="info"
          icon={<LockOutlined />}
          showIcon
          message="Die e-card Integration ist nicht aktiviert"
          description={
            <>
              <p>
                Mit der e-card Integration können Sie diverse Services der Sozialversicherung nutzen, wie z.B. die
                Abfrage des Versicherungsstatus von Patienten, oder die automatische Vervollständigung von
                Patientendaten bei der manuellen Patienteneingabe.
              </p>
              <p>Bitte kontaktieren Sie Ihr Labor bei Interesse an dieser Funktion.</p>
            </>
          }
        />
      </MainContent>
    );
  }

  if (showAssistant) {
    return <EcardSetupAssistant onHideAssistant={() => setShowAssistant(false)} />;
  }
  return <EcardConfigs onShowAssistant={() => setShowAssistant(true)} />;
};
