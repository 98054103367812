import React from 'react';
import { MainContent } from '../../../components/MainContent';
import { PageHeader } from '@ant-design/pro-components';
import { useMutation, useQuery } from '@apollo/client';
import { graphql } from '../../../graphql/generated';
import { useCurrentContextStore } from '../../../hooks/store/useCurrentContextStore.ts';
import { hasSomeRole, Role } from '../../../utils/user.ts';
import { useAuth } from 'react-oidc-context';
import { App, Button, Card, Descriptions, Empty, Flex, Popconfirm, Popover, Space, theme } from 'antd';
import { LoadingIndicator } from '../../../components/LoadingIndicator.tsx';
import { DeleteOutlined, ApiOutlined } from '@ant-design/icons';
import { EcardTest } from './EcardTest.tsx';
import { EcardConfigsQuery } from '../../../graphql/generated/graphql.ts';

const ECARD_CONFIGS_QUERY = graphql(`
  query EcardConfigs($doctorId: ID!) {
    ecardConfigs(doctorId: $doctorId) {
      id
      ipAddress
      cardReaderAddress
      cardReaderName
      taetigkeitsBereichName
      ordinationName
    }

    doctor(id: $doctorId) {
      id
      name
    }
  }
`);

const DELETE_ECARD_CONFIG_MUTATION = graphql(`
  mutation DeleteEcardConfig($id: ID!) {
    deleteEcardConfig(id: $id)
  }
`);

export type EcardConfig = NonNullable<EcardConfigsQuery['ecardConfigs']>[number];

export const EcardConfigs: React.FC<{ onShowAssistant: () => void }> = ({ onShowAssistant }) => {
  const { currentDoctorId, primaryDoctorId } = useCurrentContextStore();
  const auth = useAuth();
  const isUserRole = hasSomeRole([Role.ROLE_LR_USER], auth.user);
  const { message } = App.useApp();
  const { token } = theme.useToken();
  const [deleteEcardConfigMutation, { loading: deleteEcardConfigLoading }] = useMutation(DELETE_ECARD_CONFIG_MUTATION);

  const { data, loading, refetch } = useQuery(ECARD_CONFIGS_QUERY, {
    variables: {
      doctorId: isUserRole ? currentDoctorId : primaryDoctorId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const deleteEcardConfig = async (id: string) => {
    try {
      await deleteEcardConfigMutation({
        variables: {
          id: id,
        },
      });

      refetch();
    } catch {
      message.error('e-card Konfiguration konnte nicht entfernt werden');
    }
  };

  return (
    <MainContent size="medium">
      <PageHeader
        title={'e-card Einstellungen für ' + data?.doctor?.name}
        style={{ padding: 0, paddingBottom: 'inherit' }}
      />
      <Space size="large" direction="vertical" style={{ width: '100%' }}>
        {loading && <LoadingIndicator />}
        {!loading && data?.ecardConfigs && data.ecardConfigs.length === 0 && (
          <Flex vertical gap={token.paddingXL} align="center">
            <Empty description="Es wurde noch kein e-card konfiguriert" image={Empty.PRESENTED_IMAGE_DEFAULT} />
            {/*currently just one config is allowed*/}
            <Button type="primary" onClick={onShowAssistant}>
              Einrichtungsassistent starten
            </Button>
          </Flex>
        )}
        {!loading &&
          data?.ecardConfigs?.map((config, i) => (
            <Card
              type="inner"
              size="small"
              key={`ecard-config-${i}`}
              title={config.cardReaderName}
              extra={
                <Space>
                  <Popover
                    content={<EcardTest config={config} />}
                    destroyTooltipOnHide
                    placement="bottomRight"
                    arrow
                    trigger={['click']}
                  >
                    <Button type="text" icon={<ApiOutlined />}>
                      Funktionstest
                    </Button>
                  </Popover>
                  <Popconfirm
                    title="Wollen Sie die e-card Konfiguration wirklich entfernen?"
                    onConfirm={() => deleteEcardConfig(config.id)}
                    okText="Ja"
                    okButtonProps={{ danger: true }}
                    cancelText="Nein"
                  >
                    <Button danger type="text" icon={<DeleteOutlined />} loading={deleteEcardConfigLoading}>
                      Entfernen
                    </Button>
                  </Popconfirm>
                </Space>
              }
            >
              <Descriptions size="small" column={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 2 }}>
                <Descriptions.Item label="Anschluss-IP-Adresse">{config.ipAddress}</Descriptions.Item>
                <Descriptions.Item label="Kartenleser-Adresse">{config.cardReaderAddress}</Descriptions.Item>
                <Descriptions.Item label="Ordination">{config.ordinationName}</Descriptions.Item>
                <Descriptions.Item label="Tätigkeitsbereich">{config.taetigkeitsBereichName}</Descriptions.Item>
              </Descriptions>
            </Card>
          ))}
      </Space>
    </MainContent>
  );
};
