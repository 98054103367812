import React, { useCallback, useEffect, useState } from 'react';
import { Alert, App, Button, Card, Drawer, Space } from 'antd';
import { MenuOutlined, PrinterOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { aisImport, ImportFileParseResult } from '../../utils/aisImport';
import { useDoctorHasAis } from '../../hooks/useDoctorHasAis';
import { PatientInfo } from './PatientInfo';
import { CustomEventType } from '../../@types/CustomEventType';
import { usePrintEmptyLabel } from '../../hooks/usePrintEmptyLabel';
import { Forms } from '../forms/Forms.tsx';
import { useAuth } from 'react-oidc-context';
import { useCurrentContextStore } from '../../hooks/store/useCurrentContextStore.ts';
import { hasFrontDeskRole } from '../../utils/user.ts';

export const QuickActions: React.FC = () => {
  const auth = useAuth();
  const printEmptyLabel = usePrintEmptyLabel();
  const { currentDoctorId, primaryDoctorId } = useCurrentContextStore();
  const hasAis = useDoctorHasAis(hasFrontDeskRole(auth.user) ? primaryDoctorId : currentDoctorId);
  const [open, setOpen] = useState(false);
  const [importFileResult, setImportFileResult] = useState<ImportFileParseResult | null>(null);
  const [patientDataError, setPatientDataError] = useState('');
  const { message } = App.useApp();

  const importFromAis = useCallback(async () => {
    clean();

    if (!hasAis) {
      setPatientDataError('Für Ihren Account ist keine AIS-Schnittstelle konfiguriert');
      return;
    }

    if (!window.nativeApi) {
      setPatientDataError('AIS-Patientendaten können nicht gelesen werden, da Sie nicht Medcom Desktop verwenden');
      return;
    }

    try {
      const result = await aisImport(
        auth.user?.access_token ?? '',
        hasFrontDeskRole(auth.user) ? primaryDoctorId : currentDoctorId
      );
      setImportFileResult(result);
    } catch (e) {
      setPatientDataError('Fehler beim Lesen der AIS-Patientendaten oder keine AIS-Patientendaten vorhanden');
    }
  }, [primaryDoctorId, currentDoctorId, hasAis, auth]);

  useEffect(() => {
    const listener = () => {
      if (open) {
        importFromAis();
      }
    };
    document.addEventListener(CustomEventType.AIS_IMPORT_FILE_CHANGE, listener);
    return () => {
      document.removeEventListener(CustomEventType.AIS_IMPORT_FILE_CHANGE, listener);
    };
  }, [open, importFromAis]);

  const onOpen = () => {
    setOpen(true);
    importFromAis();
  };

  const clean = () => {
    setPatientDataError('');
    setImportFileResult(null);
  };

  const onClose = () => {
    setOpen(false);
    clean();
  };

  const patientData = importFileResult?.patientData;
  const patientDataValid = patientData && !importFileResult?.invalidPatientFields.length;

  return (
    <>
      <MenuOutlined
        className={css`
          font-size: 20px;
          margin-right: 12px;
          cursor: pointer;
        `}
        onClick={onOpen}
      />
      <Drawer
        title="Aktionen"
        placement="right"
        open={open}
        width="50%"
        rootClassName={css`
          .ant-drawer-content-wrapper {
            max-width: 800px;
            min-width: 500px;
          }
        `}
        onClose={onClose}
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          <Card size="small" title="Aktuelle AIS-Patientendaten" type="inner">
            {patientDataError && <Alert type="warning" showIcon message={patientDataError} />}
            {patientDataValid && importFileResult?.patientData && (
              <PatientInfo patientData={importFileResult.patientData} />
            )}
            {!!importFileResult?.invalidPatientFields.length && (
              <Alert
                type="warning"
                showIcon
                message={`AIS-Patientendaten unvollständig. Folgende Felder konnten nicht korrekt vom AIS übernommen werden: ${importFileResult.invalidPatientFields.join(
                  ', '
                )}`}
              />
            )}
          </Card>
          <Card size="small" title="Leer-Etiketten" hidden={!patientDataValid} type="inner">
            <Button
              icon={<PrinterOutlined />}
              onClick={() =>
                printEmptyLabel(
                  {
                    doctorId: currentDoctorId,
                    patientTitle: patientData?.title ?? '',
                    patientLastName: patientData?.lastName ?? '',
                    patientFirstName: patientData?.firstName ?? '',
                    patientSvnr: patientData?.svnr ?? '',
                  },
                  1,
                  () => message.error('Beim Drucken ist ein Fehler aufgetreten'),
                  () => message.success('Leer-Etikett wurde an den Drucker gesendet')
                )
              }
            >
              Leer-Etikett drucken
            </Button>
          </Card>
          <Card size="small" title="Formulare" hidden={!patientDataValid} type="inner">
            {patientData && <Forms patientData={patientData} />}
          </Card>
        </Space>
      </Drawer>
    </>
  );
};
