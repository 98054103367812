import React, { useEffect, useMemo, useState } from 'react';
import {
  Alert,
  App,
  Button,
  DatePicker,
  Descriptions,
  Divider,
  Flex,
  Form as AntForm,
  Input,
  Modal,
  Space,
  Steps,
  Switch,
  Tag,
  theme,
  Tooltip,
  Typography,
} from 'antd';
import { MainContent } from '../../components/MainContent';
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  LeftOutlined,
  PrinterOutlined,
  ReloadOutlined,
  SaveOutlined,
  SendOutlined,
  TableOutlined,
  UserOutlined,
  WarningTwoTone,
} from '@ant-design/icons';
import { Navigate, useNavigate } from 'react-router';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { PatientHeader } from './PatientHeader';
import { ParameterInfoModal } from './ParameterInfoModal';
import { insuranceName } from '../../utils/patient';
import { TestTubeColor } from '../../components/TestTubeColor';
import { testTubeColorProperties } from '../../utils/enumHelpers';
import { usePrintRequest } from '../../hooks/usePrintRequest';
import { useSendRequests } from '../../hooks/useSendRequests';
import { css } from '@emotion/css';
import { useForm } from 'antd/es/form/Form';
import { FormRenderer } from '../forms/FormRenderer';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import dayjs from 'dayjs';
import { API_DATETIME_FORMAT, SHORT_DATETIME_FORMAT } from '../../utils/dateFormatUtils';
import { FormLabel } from '../../components/FormLabel';
import { Localization, SelectedParameter, useAppStore } from '../../hooks/store/useAppStore';
import { useAisExportRequest } from '../../hooks/useAisExportRequest';
import { createFormFieldValues } from '../../utils/form.ts';
import { AdditionalDataTable } from '../../components/AdditionalDataTable.tsx';
import { getFragmentData, graphql } from '../../graphql/generated';
import {
  ParameterSelection_RequestableParameterFragment,
  RequestSummary_SavedRequestFragment,
} from '../../graphql/generated/graphql.ts';
import { LoadingIndicator } from '../../components/LoadingIndicator.tsx';
import { usePrintEmptyLabel } from '../../hooks/usePrintEmptyLabel.ts';
import { ParamList } from './summary/ParamList.tsx';
import { useCurrentContextStore } from '../../hooks/store/useCurrentContextStore.ts';
import { Localizations } from './summary/Localizations.tsx';
import { hasFrontDeskRole } from '../../utils/user.ts';
import { useAuth } from 'react-oidc-context';
import { CostsSummary } from './CostsSummary.tsx';
import { ImportantInfos } from './summary/ImportantInfos.tsx';

const { useToken } = theme;

const REQUEST_SUMMARY_QUERY = graphql(`
  query RequestSummary($catalogParamIds: [ID!]!, $requestIdForReorder: ID, $doctorId: ID!) {
    insurances {
      id
      code
      shortName
    }

    requiredForms(catalogParamIds: $catalogParamIds, requestIdForReorder: $requestIdForReorder, doctorId: $doctorId) {
      id
      name
      header
      description
      fields {
        id
        name
        type
        required
        placeholder
        label
        help
        defaultValue
        regex
        options {
          label
          selected
          value
        }
      }
    }

    doctor(id: $doctorId) {
      id
      showPriceSuggestion
      showPrices
      patientBilling
      flipParamNames
      addEmptyLabels
    }
  }
`);

const CREATE_REQUEST_MUTATION = graphql(`
  mutation CreateRequest($input: CreateRequestInput!) {
    createRequest(input: $input) {
      ...RequestSummary_SavedRequest
    }
  }
`);

const UPDATE_REQUEST_MUTATION = graphql(`
  mutation UpdateRequest($input: UpdateRequestInput!) {
    updateRequest(input: $input) {
      ...RequestSummary_SavedRequest
    }
  }
`);

const REORDER_MUTATION = graphql(`
  mutation Reorder($input: ReorderInput!) {
    reorder(input: $input) {
      ...RequestSummary_SavedRequest
    }
  }
`);

const SavedRequestFragment = graphql(`
  fragment RequestSummary_SavedRequest on Request {
    id
    orders {
      aisExportedAt
      orderFiles {
        id
        printedAt
        printable
        printRequired
        printBeforeSend
        printCopies
      }
      orderLabels {
        id
        printedAt
        specimenId
        testTubeId
        location
      }
    }
    additionalData
  }
`);

type GeneratedLabel = {
  id: string;
  specimenId: string;
  testTubeId: string;
  testTubeName: string;
  testTubeColor: string;
  specimenName: string;
  testTubeLabelText: string;
  location: string;
  repetition: number;
};

/** same logic as in backend */
const generateLabels = (selectedParams: SelectedParameter[], localizations: Localization[]): GeneratedLabel[] => {
  const labels: GeneratedLabel[] = [];
  for (const selectedParam of selectedParams) {
    for (const specimen of selectedParam.specimens) {
      for (const testTube of specimen.testTubes) {
        if (specimen.requiresLocalizations) {
          for (const localization of localizations.filter(
            it => it.specimenId === specimen.id && it.paramId === selectedParam.id
          )) {
            if (
              !labels.some(
                it =>
                  it.specimenId === specimen.id &&
                  it.testTubeId === testTube.id &&
                  it.location === localization.location
              )
            ) {
              labels.push({
                id: specimen.id + '_' + testTube.id + '_' + localization.location,
                specimenId: specimen.id,
                testTubeId: testTube.id,
                specimenName: specimen.name,
                testTubeName: testTube.name,
                testTubeColor: testTube.color,
                testTubeLabelText: testTube.labelText,
                location: localization.location,
                repetition: 0,
              });
            }
          }
        } else {
          let numLabels;
          if (testTube.volume === 0) {
            numLabels = 1;
          } else {
            const sumParamVolumes = selectedParams
              .filter(p => p.specimens.some(s => s.id === specimen.id && s.testTubes.some(tt => tt.id === testTube.id)))
              .reduce((sum, current) => sum + current.volume, 0);
            numLabels = Math.ceil(sumParamVolumes / testTube.volume);
          }

          for (let i = 0; i < numLabels; i++) {
            if (
              !labels.some(it => it.specimenId === specimen.id && it.testTubeId === testTube.id && it.repetition === i)
            ) {
              labels.push({
                id: specimen.id + '_' + testTube.id,
                specimenId: specimen.id,
                testTubeId: testTube.id,
                specimenName: specimen.name,
                testTubeName: testTube.name,
                testTubeColor: testTube.color,
                testTubeLabelText: testTube.labelText,
                location: '',
                repetition: i,
              });
            }
          }
        }
      }
    }
  }
  return labels;
};

export const RequestSummary: React.FC = () => {
  const { token } = useToken();
  const styles = {
    flexContainer: css`
      display: flex;
      flex-direction: column;
      height: 100%;
    `,
    growZero: css`
      flex-grow: 0;
    `,
    growFull: css`
      flex-grow: 1;
      overflow: hidden;
    `,
    innerContainer: css`
      height: 100%;
      overflow-y: auto;
      padding-right: 4px; // padding to scrollbars
    `,
    actions: css`
      display: flex;
      margin-bottom: ${token.paddingXS}px; // show button click border
    `,
    rightActions: css`
      margin-left: auto;
    `,
  };

  type SaveAction = 'save' | 'save-print' | 'save-print-send';

  const {
    patientData,
    paramQueue,
    formData,
    setFormData,
    labInfo,
    setLabInfo,
    acute,
    setAcute,
    diagnose,
    setDiagnose,
    requestIdForUpdate,
    requestIdForReorder,
    setRequestIdForUpdate,
    setRequestIdForReorder,
    sampledAt,
    setSampledAt,
    additionalData,
    localizations,
  } = useAppStore();
  const { currentDoctorId, primaryDoctorId } = useCurrentContextStore();
  const navigate = useNavigate();
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [saveLoadingAction, setSaveLoadingAction] = useState<SaveAction | null>(null);
  const [baseForm] = useForm();
  const [formsForm] = useForm();

  const [infoParam, setInfoParam] = useState<SelectedParameter | null>(null);
  const print = usePrintRequest();
  const printEmptyLabel = usePrintEmptyLabel();
  const aisExport = useAisExportRequest();
  const send = useSendRequests();
  const { message } = App.useApp();
  const auth = useAuth();
  const isFrontDesk = hasFrontDeskRole(auth.user);

  const { data, loading } = useQuery(REQUEST_SUMMARY_QUERY, {
    variables: {
      doctorId: isFrontDesk ? primaryDoctorId : currentDoctorId,
      catalogParamIds: paramQueue.selectedParameters?.map(it => it.id),
      requestIdForReorder: requestIdForReorder ? requestIdForReorder : null,
    },
    skip: !patientData || !paramQueue.selectedParameters.length,
    fetchPolicy: 'cache-and-network',
  });

  const [createRequestMutation] = useMutation(CREATE_REQUEST_MUTATION);
  const [updateRequestMutation] = useMutation(UPDATE_REQUEST_MUTATION);
  const [reorderMutation] = useMutation(REORDER_MUTATION);

  const onInfo = (param: SelectedParameter) => {
    setInfoParam(param);
  };

  const saveRequest = async (action: SaveAction) => {
    let request: RequestSummary_SavedRequestFragment;
    setSaveDisabled(true);
    setSaveLoadingAction(action);

    try {
      await baseForm.validateFields();
    } catch (error) {
      baseForm.scrollToField((error as ValidateErrorEntity).errorFields[0].name, {
        block: 'center',
      });
      setSaveDisabled(false);
      setSaveLoadingAction(null);
      return;
    }

    try {
      await formsForm.validateFields();
    } catch (error) {
      formsForm.scrollToField((error as ValidateErrorEntity).errorFields[0].name, {
        block: 'center',
      });
      setSaveDisabled(false);
      setSaveLoadingAction(null);
      return;
    }

    const formFieldValues = createFormFieldValues(data?.requiredForms, formsForm.getFieldsValue());

    try {
      if (requestIdForUpdate) {
        const result = await updateRequestMutation({
          variables: {
            input: {
              requestId: requestIdForUpdate,
              patientData: patientData!,
              selectedParameters: paramQueue.selectedParameters.map(param => ({
                parameterId: param.id,
                parameterShortName: param.shortName,
                parameterLongName: param.longName,
                billingType: param.billingInfo.billingType,
                diagnoseId: param.billingInfo.diagnoseId,
                specialRateId: param.billingInfo.specialRateId,
              })),
              diagnose: diagnose,
              labInfo: labInfo,
              acute: acute,
              formFieldValues: formFieldValues,
              sampledAt: sampledAt,
              additionalData: additionalData,
              localizations: localizations,
            },
          },
        });
        request = getFragmentData(SavedRequestFragment, result.data?.updateRequest)!;
      } else if (requestIdForReorder) {
        const result = await reorderMutation({
          variables: {
            input: {
              requestId: requestIdForReorder,
              selectedParameters: paramQueue.selectedParameters.map(param => ({
                parameterId: param.id,
                parameterShortName: param.shortName,
                parameterLongName: param.longName,
                billingType: param.billingInfo.billingType,
                diagnoseId: param.billingInfo.diagnoseId,
                specialRateId: param.billingInfo.specialRateId,
              })),
              diagnose: diagnose,
              labInfo: labInfo,
              acute: acute,
              formFieldValues: formFieldValues,
              localizations: localizations,
            },
          },
        });
        request = getFragmentData(SavedRequestFragment, result.data?.reorder)!;
      } else {
        const result = await createRequestMutation({
          variables: {
            input: {
              doctorId: currentDoctorId,
              patientData: patientData!,
              selectedParameters: paramQueue.selectedParameters.map(param => ({
                parameterShortName: param.shortName,
                parameterLongName: param.longName,
                parameterId: param.id,
                billingType: param.billingInfo.billingType,
                diagnoseId: param.billingInfo.diagnoseId,
                specialRateId: param.billingInfo.specialRateId,
              })),
              diagnose: diagnose,
              labInfo: labInfo,
              acute: acute,
              formFieldValues: formFieldValues,
              sampledAt: sampledAt,
              additionalData: additionalData,
              localizations: localizations,
            },
          },
        });
        request = getFragmentData(SavedRequestFragment, result.data?.createRequest)!;
      }

      if (action === 'save-print-send' && !requestIdForReorder) {
        const response = await send([request.id]);
        request = response.data!.sendRequests![0];
      }

      if (action === 'save-print' || action === 'save-print-send') {
        await print(
          action === 'save-print' ? 'before-send' : 'send',
          request,
          () => message.error('Beim Drucken ist ein Fehler aufgetreten'),
          () => {}, // do nothing
          () => message.success('Etiketten & Dokumente wurden an den Drucker gesendet'),
          () => message.error('Anforderungsstatus kann nicht gesetzt werden'),
          () => {} // do nothing
        );

        if (data?.doctor?.addEmptyLabels) {
          printEmptyLabel(
            {
              doctorId: currentDoctorId,
              patientTitle: patientData?.title ?? '',
              patientLastName: patientData?.lastName ?? '',
              patientFirstName: patientData?.firstName ?? '',
              patientSvnr: patientData?.svnr ?? '',
            },
            data.doctor.addEmptyLabels,
            () => message.error('Beim Drucken der Leeretiketten ist ein Fehler aufgetreten'),
            () => {} // silently ignore
          );
        }
      }

      message.success(
        requestIdForReorder ? 'Nachforderung wurde erfolgreich erstellt' : 'Anforderung wurde erfolgreich erstellt'
      );
      if (action === 'save-print-send') {
        await aisExport(
          request,
          msg => message.error('Beim AIS-Export ist ein Fehler aufgetreten: ' + msg),
          () => message.success('Dokumente für das AIS wurden erfolgreich exportiert')
        );
        navigate('/gesendete-anforderungen');
      } else {
        navigate('/offene-anforderungen');
      }
      setRequestIdForUpdate('');
      setRequestIdForReorder('');
    } catch (e) {
      if (
        e instanceof ApolloError &&
        e.graphQLErrors.length > 0 &&
        e.graphQLErrors[0].extensions?.classification === 'VALIDATION_ERROR'
      ) {
        setErrorModalVisible(true);
        return;
      }
      message.error(
        'Es ist ein unbekannter Fehler beim Speichern der Anforderung aufgetreten. Bitte kontaktieren Sie den Support.'
      );
    } finally {
      setSaveDisabled(false);
      setSaveLoadingAction(null);
    }
  };

  const handleFormChange = () => {
    setFormData(formsForm.getFieldsValue());
  };

  useEffect(() => {
    if (data?.requiredForms && formData) {
      formsForm.setFieldsValue(formData);
    }
  }, [formsForm, formData, data]);

  const labels = useMemo(
    () => generateLabels(paramQueue.selectedParameters, localizations),
    [paramQueue.selectedParameters, localizations]
  );

  if (patientData === null) {
    return <Navigate replace to="/" />;
  }

  if (!paramQueue.selectedParameters.length) {
    return <Navigate replace to="/anforderung/parameterauswahl" />;
  }

  if (loading) {
    return <LoadingIndicator />;
  }

  const insurance = insuranceName(patientData, data?.insurances ?? []);

  const diagnoses = paramQueue.selectedParameters
    .filter(it => it.billingInfo.diagnoseId !== null)
    .reduce<string[]>(
      (diagnoses, param) =>
        diagnoses.some(diagnoseText => diagnoseText === param.billingInfo.text)
          ? diagnoses
          : [...diagnoses, param.billingInfo.text],
      []
    )
    .filter(it => it !== '')
    .join(', ');

  const diagnoseRequired = paramQueue.selectedParameters
    .reduce<
      ParameterSelection_RequestableParameterFragment['lab'][]
    >((labs, param) => (labs.some(l => l.id === param.lab.id) ? labs : [...labs, ...(param.lab.diagnoseRequired ? [param.lab] : [])]), [])
    .some(lab =>
      paramQueue.selectedParameters.filter(it => it.lab.id === lab.id).every(it => it.billingInfo.diagnoseId === null)
    );

  const hasAcuteParams = paramQueue.selectedParameters.some(it => it.acute) ?? false;

  return (
    <MainContent size="medium" heightType="fullHeight">
      <div className={styles.flexContainer}>
        <Steps
          className={styles.growZero}
          items={[
            {
              status: 'finish',
              title: 'Patient',
              icon: <UserOutlined />,
            },
            {
              status: 'finish',
              title: 'Parameterauswahl',
              icon: <TableOutlined />,
            },
            {
              status: 'process',
              title: requestIdForUpdate
                ? 'Bestehende Anforderung abschließen'
                : requestIdForReorder
                  ? 'Nachforderung abschließen'
                  : 'Neue Anforderung abschließen',
              icon: <SendOutlined />,
            },
          ]}
        />
        <div className={styles.growFull}>
          <div className={styles.flexContainer}>
            <PatientHeader
              patientData={patientData}
              showDefaultBillingChooser={false}
              availableBillingTypes={[]}
              insuranceName={insurance}
              patientBilling={data?.doctor?.patientBilling || false}
            />
            <div className={styles.innerContainer}>
              <Space direction="vertical" size="large" style={{ width: '100%' }}>
                <ParamList
                  params={paramQueue.selectedParameters}
                  onInfo={onInfo}
                  flipParamNames={data?.doctor?.flipParamNames ?? false}
                  limit={9}
                  showPrices={data?.doctor?.showPrices ?? true}
                />
                <ImportantInfos
                  flipParamNames={data?.doctor?.flipParamNames ?? false}
                  selectedParams={paramQueue.selectedParameters}
                />
                <Localizations
                  selectedParams={paramQueue.selectedParameters}
                  localizations={localizations}
                  requestIdForReorder={requestIdForReorder}
                  flipParamNames={data?.doctor?.flipParamNames ?? false}
                />
                {data?.requiredForms && data.requiredForms.flatMap(it => it.fields).length > 0 && (
                  <FormRenderer formInstance={formsForm} forms={data.requiredForms} onChange={handleFormChange} />
                )}
                <AntForm form={baseForm} initialValues={{ diagnose: diagnose }}>
                  <Descriptions column={1} size="small" bordered labelStyle={{ width: '40%' }}>
                    {hasAcuteParams && (
                      <Descriptions.Item
                        label={
                          <FormLabel
                            required={false}
                            help="Wenn aktiviert werden alle akut anforderbaren Parameter akut angefordert"
                            label={
                              <>
                                <ClockCircleOutlined /> Akut anfordern
                              </>
                            }
                            htmlFor="acute"
                          />
                        }
                      >
                        <Switch id="acute" checked={acute} onChange={checked => setAcute(checked)} />
                      </Descriptions.Item>
                    )}
                    {!requestIdForReorder && (
                      <Descriptions.Item
                        label={
                          <FormLabel
                            required={false}
                            help="Wenn Sie das Feld leer lassen, oder der ausgewählte Zeitpunkt nach dem Sendezeitpunkt liegt,
                              dann wird automatisch der Sendezeitpunkt für die Probenentnahme
                              verwendet."
                            label="Probenentnahme"
                            htmlFor="sampledAt"
                          />
                        }
                      >
                        <DatePicker
                          className={css`
                            width: 100%;
                          `}
                          id="sampledAt"
                          showTime
                          placeholder="Datum und Uhrzeit auswählen"
                          format={SHORT_DATETIME_FORMAT}
                          value={sampledAt ? dayjs(sampledAt) : null}
                          onChange={value => setSampledAt(value ? value.format(API_DATETIME_FORMAT) : null)}
                        />
                      </Descriptions.Item>
                    )}
                    <Descriptions.Item
                      label={
                        <FormLabel
                          required={diagnoseRequired}
                          help=""
                          label="Diagnosen und Informationen für die Befundvalidierung"
                          htmlFor="diagnose"
                        />
                      }
                    >
                      <AntForm.Item
                        name="diagnose"
                        className={css`
                          margin-bottom: 0;
                        `}
                        rules={[
                          {
                            type: 'string',
                            required: diagnoseRequired,
                            whitespace: true,
                            message: 'Bitte geben Sie eine Diagnose an',
                          },
                        ]}
                      >
                        <Input.TextArea
                          id="diagnose"
                          value={diagnose}
                          rows={3}
                          onChange={e => setDiagnose(e.target.value)}
                        />
                      </AntForm.Item>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<FormLabel required={false} help="" label="Informationen ans Labor" htmlFor="labInfo" />}
                    >
                      <Input
                        id="labInfo"
                        value={labInfo}
                        onChange={e => setLabInfo(e.target.value)}
                        placeholder="z.B. klinische Angaben, Lokalisation"
                      />
                    </Descriptions.Item>
                    {!!diagnoses.length && (
                      <Descriptions.Item label="Diagnosen für die Verrechnung mit der Kasse">
                        {diagnoses}
                      </Descriptions.Item>
                    )}
                    <Descriptions.Item label="Etiketten">
                      {requestIdForReorder ? (
                        <Typography.Text type="secondary">
                          Keine neuen Etiketten für Nachforderung benötigt
                        </Typography.Text>
                      ) : labels.length ? (
                        <Space direction="vertical">
                          {!!labels.filter(it => it.repetition > 0).length && (
                            <Alert
                              type="warning"
                              message="Das Fassungsvermögen mancher Probenbehälter wurde überschritten, daher werden zusätzliche Etiketten erzeugt."
                            />
                          )}
                          <Flex wrap="wrap" gap="small">
                            {labels.map(label => (
                              <Tooltip key={label.id} title={label.testTubeName}>
                                <Tag
                                  className={css`
                                    margin: 0;
                                  `}
                                >
                                  <Flex gap={4} align="center">
                                    <TestTubeColor
                                      properties={testTubeColorProperties(label.testTubeColor)}
                                      withText={false}
                                    />
                                    {label.specimenName}{' '}
                                    <Typography.Text type="secondary">
                                      <small>
                                        {label.testTubeLabelText} {label.location ? ' | ' + label.location : ''}
                                      </small>
                                    </Typography.Text>
                                  </Flex>
                                </Tag>
                              </Tooltip>
                            ))}
                          </Flex>
                        </Space>
                      ) : (
                        <Typography.Text type="secondary">Keine Etiketten benötigt</Typography.Text>
                      )}
                    </Descriptions.Item>
                    {data?.doctor?.showPrices && (
                      <Descriptions.Item label="Kosten">
                        <CostsSummary
                          entries={paramQueue.selectedParameters.map(it => ({
                            billingType: it.billingInfo.billingType,
                            price: it.billingInfo.price ?? null,
                            text: it.billingInfo.text,
                            pricePatient: it.pricePatient ?? null,
                          }))}
                          showPriceSuggestion={data?.doctor?.showPriceSuggestion ?? false}
                        />
                      </Descriptions.Item>
                    )}
                  </Descriptions>
                </AntForm>
              </Space>
              {Object.keys(additionalData).length > 0 && (
                <>
                  <h3
                    className={css`
                      margin-top: ${token.paddingMD}px;
                      margin-bottom: ${token.paddingMD}px;
                      font-weight: 600;
                    `}
                  >
                    Zusatzdaten
                  </h3>
                  <AdditionalDataTable additionalData={additionalData} />
                </>
              )}
            </div>
            <Divider />
            <div className={styles.actions}>
              <Button icon={<LeftOutlined />} onClick={() => navigate('/anforderung/parameterauswahl')}>
                Zurück zur Parameterauswahl
              </Button>
              <div className={styles.rightActions}>
                <Space direction="horizontal">
                  {!requestIdForReorder && (
                    <>
                      <Button
                        icon={<SaveOutlined />}
                        onClick={() => saveRequest('save')}
                        disabled={saveDisabled}
                        loading={saveLoadingAction === 'save'}
                      >
                        Speichern
                      </Button>
                      <Button
                        icon={<PrinterOutlined />}
                        loading={saveLoadingAction === 'save-print'}
                        onClick={() => saveRequest('save-print')}
                        disabled={!window.nativeApi || saveDisabled}
                      >
                        Speichern & Drucken
                      </Button>
                      <Button
                        type="primary"
                        loading={saveLoadingAction === 'save-print-send'}
                        icon={<CheckCircleOutlined />}
                        onClick={() => saveRequest('save-print-send')}
                        disabled={!window.nativeApi || saveDisabled}
                      >
                        Speichern, Drucken & Senden
                      </Button>
                    </>
                  )}
                  {requestIdForReorder && (
                    <Button
                      type="primary"
                      icon={<CheckCircleOutlined />}
                      onClick={() => saveRequest('save-print-send')}
                      disabled={saveDisabled}
                    >
                      Nachforderung abschließen
                    </Button>
                  )}
                </Space>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ParameterInfoModal
        parameter={infoParam}
        onClose={() => setInfoParam(null)}
        flipParamNames={data?.doctor?.flipParamNames ?? false}
        showPrices={data?.doctor?.showPrices ?? true}
      />
      <Modal
        title={
          <>
            <WarningTwoTone twoToneColor="#f5222d" /* red-6 */ /> Die Anforderung kann nicht abgeschlossen werden
          </>
        }
        open={errorModalVisible}
        closable={false}
        keyboard={false}
        maskClosable={false}
        cancelButtonProps={{ hidden: true }}
        okText="Vorgang erneut starten"
        okButtonProps={{ icon: <ReloadOutlined /> }}
        onOk={() => navigate('/anforderung')}
      >
        <p>Bei der Validierung der anzufordernden Parameter wurde ein Problem gefunden.</p>
        <p>
          Möglicherweise wurden die Parameterdefinitionen während Ihrer Anforderungserstellung geändert, weshalb Sie die
          Anforderung nicht erstellen können.
        </p>
        <p>
          Bitte wiederholen Sie den Vorgang. Wenden Sie sich bitte an unseren Support, falls das Problem weiterhin
          bestehen sollte.
        </p>
        <Alert
          type="info"
          showIcon
          message="Tipp: Schließen Sie den Prozess der Anforderungserstellung so schnell wie möglich ab. Zu lange geöffnete Prozesse können diese Meldung verursachen."
        />
      </Modal>
    </MainContent>
  );
};
