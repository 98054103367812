import { useQuery } from '@apollo/client';
import { hasAnyRelevantRole } from '../utils/user';
import { graphql } from '../graphql/generated';
import { useAuth } from 'react-oidc-context';

const DOCTOR_ECARD_ENABLED_QUERY = graphql(`
  query DoctorEcardEnabled($id: ID!) {
    doctor(id: $id) {
      id
      ecardEnabled
    }
  }
`);

export const useDoctorEcardEnabled = (doctorId: string): boolean => {
  const auth = useAuth();
  const hasAnyRole = hasAnyRelevantRole(auth.user);

  const { data } = useQuery(DOCTOR_ECARD_ENABLED_QUERY, {
    variables: {
      id: doctorId,
    },
    fetchPolicy: 'cache-and-network',
    skip: !doctorId || !hasAnyRole,
  });

  return data?.doctor?.ecardEnabled === true;
};
