import React from 'react';
import { App, Form as AntForm, Input, Modal } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { useForm } from 'antd/es/form/Form';
import { graphql } from '../../../../graphql/generated';
import { FormCustomizer, FormInput } from '../../../../graphql/generated/graphql.ts';

const CREATE_FORM_MUTATION = graphql(`
  mutation CreateForm($input: FormInput!) {
    createForm(input: $input) {
      id
    }
  }
`);

export const CreateFormModal: React.FC<{
  labId: string;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}> = ({ labId, open, onClose, onSuccess }) => {
  const [createFormMutation, { loading }] = useMutation(CREATE_FORM_MUTATION);
  const [form] = useForm<FormInput>();
  const { message } = App.useApp();

  const create = async (values: FormInput) => {
    try {
      await createFormMutation({
        variables: {
          input: {
            ...values,
            labId: labId,
            fields: [],
          },
        },
      });

      message.success('Formular wurde erstellt');
      onSuccess();
      closeAndReset();
    } catch (e) {
      message.error('Formular konnte nicht erstellt werden');
    }
  };

  const closeAndReset = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      width={600}
      title="Neues Formular erstellen"
      okText="Erstellen"
      okButtonProps={{ icon: <PlusCircleOutlined /> }}
      open={open}
      onCancel={closeAndReset}
      onOk={form.submit}
      confirmLoading={loading}
      destroyOnClose
    >
      <AntForm<FormInput>
        form={form}
        layout="vertical"
        name="create-form"
        preserve
        initialValues={{
          labId: labId,
          name: '',
          header: '',
          description: '',
          pickup: false,
          printRequired: false,
          printBeforeSend: false,
          printCopies: 1,
          direct: false,
          customizer: FormCustomizer.NONE,
          restore: true,
          doctorIds: [],
          requiredByFrontDesk: false,
        }}
        onFinish={() => create(form.getFieldsValue(true))}
      >
        <AntForm.Item name="name" label="Bezeichnung" rules={[{ required: true, whitespace: true }]}>
          <Input />
        </AntForm.Item>
      </AntForm>
    </Modal>
  );
};
