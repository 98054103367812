import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useAppVersionStore } from '../hooks/store/useAppVersionStore.ts';

export const AppVersionAutoUpdater: React.FC = () => {
  const location = useLocation();
  const { bannerVisible } = useAppVersionStore();

  useEffect(() => {
    const shouldUpdate = bannerVisible();
    if (!shouldUpdate) {
      // no update available
      return;
    }

    if (location.state) {
      // it's a location change with state involved, ignore it
      return;
    }

    if (
      location.pathname === '/anforderung/parameterauswahl' ||
      location.pathname === '/anforderung/patient-erfassung' ||
      location.pathname === '/anforderung/uebersicht'
    ) {
      // we don't want to lose data when the user is currently in a request
      return;
    }

    window.location.reload();
  }, [location, bannerVisible]);

  return null;
};
