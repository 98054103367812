import React, { CSSProperties } from 'react';
import { Select, Typography } from 'antd';
import { TestTubeColor as TestTubeColorComp } from './TestTubeColor';
import { testTubeColorProperties, translateTestTubeSupplier } from '../utils/enumHelpers';
import { TestTubeColor, TestTubeSupplier } from '../graphql/generated/graphql.ts';

const { Option } = Select;

type TestTube = {
  id: string;
  name: string;
  supplier: TestTubeSupplier;
  color: TestTubeColor;
};

interface TestTubeSelectProps {
  id?: string;
  multiple?: boolean;
  style?: CSSProperties;
  testTubes: TestTube[];
  selected?: string | string[] | undefined;
  onChange?: (value: string | string[]) => void;
}

export const TestTubeSelect: React.FC<TestTubeSelectProps> = React.memo(
  ({ id, multiple, style, testTubes, selected, onChange }) => {
    return (
      <Select
        id={id}
        showSearch
        allowClear
        mode={multiple ? 'multiple' : undefined}
        style={style}
        optionFilterProp="search"
        optionLabelProp="taglabel"
        onChange={onChange}
        value={selected}
        placeholder="Suche nach Probenbehälter"
      >
        {[...testTubes]
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(testTube => {
            const label = `${testTube.name} - ${translateTestTubeSupplier(testTube.supplier)}`;
            return (
              <Option key={testTube.id} value={testTube.id} taglabel={label} search={label}>
                <div>{label}</div>
                <Typography.Text type="secondary">
                  <TestTubeColorComp properties={testTubeColorProperties(testTube.color)} />
                </Typography.Text>
              </Option>
            );
          })}
      </Select>
    );
  }
);
