import React, { useEffect, useState } from 'react';
import { MainContent } from '../../../components/MainContent';
import { useParams } from 'react-router';
import { Button, Input, Table, theme, Tooltip } from 'antd';
import { useQuery } from '@apollo/client';
import { ColumnsType } from 'antd/es/table';
import { EditOutlined, InfoCircleOutlined, PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { useDebounce } from 'use-debounce';
import { Markable } from '../../../components/Markable';
import { CreateDiagnoseModal } from './diagnoses/CreateDiagnoseModal';
import { UpdateDiagnoseModal } from './diagnoses/UpdateDiagnoseModal';
import { tableActionCell } from '../../../styles/globalCss';
import { graphql } from '../../../graphql/generated';
import { DiagnosesQuery } from '../../../graphql/generated/graphql.ts';

export const DIAGNOSES_QUERY = graphql(`
  query Diagnoses($labId: ID!) {
    labDiagnoses(labId: $labId) {
      id
      name
      text
    }
  }
`);

export type Diagnose = NonNullable<DiagnosesQuery['labDiagnoses']>[number];

export const Diagnoses: React.FC = () => {
  const { token } = theme.useToken();
  const { id: labId } = useParams<{ id: string }>();
  const [search, setSearch] = useState<string | null>(null);
  const [debouncedSearch] = useDebounce(search, 250);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editDiagnose, setEditDiagnose] = useState<Diagnose | null>(null);
  const [filteredDiagnoses, setFilteredDiagnoses] = useState<Diagnose[]>([]);

  const { data, loading, refetch } = useQuery(DIAGNOSES_QUERY, {
    variables: {
      labId: labId ?? '',
    },
    fetchPolicy: 'cache-and-network',
    pollInterval: 60000,
  });

  const columns: ColumnsType<Diagnose> = [
    {
      title: 'Bezeichnung',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: 150,
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: value => <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>,
    },
    {
      title: 'Text',
      dataIndex: 'text',
      key: 'text',
      ellipsis: true,
      sorter: (a, b) => a.text.localeCompare(b.text),
      render: value => <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>,
    },
    {
      title: '',
      key: 'actions',
      fixed: 'right',
      align: 'right',
      ellipsis: true,
      width: '50px',
      className: tableActionCell,
      render: (_, record) => <Button icon={<EditOutlined />} type="text" onClick={() => setEditDiagnose(record)} />,
    },
  ];

  useEffect(() => {
    const filtered = data?.labDiagnoses?.filter(d => {
      if (debouncedSearch && debouncedSearch.length) {
        const searchValue = debouncedSearch.toLowerCase();
        return d.name.toLowerCase().includes(searchValue) || d.text.toLowerCase().includes(searchValue);
      }
      return true;
    });
    setFilteredDiagnoses(filtered ?? []);
  }, [debouncedSearch, data]);

  return (
    <MainContent>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: token.paddingSM }}>
        <Input
          autoFocus
          allowClear
          placeholder="Suche"
          value={search ?? ''}
          onChange={e => setSearch(e.target.value)}
          prefix={<SearchOutlined />}
          suffix={
            <Tooltip title="Suche nach Name oder Text">
              <InfoCircleOutlined />
            </Tooltip>
          }
          style={{ width: '300px', marginRight: token.paddingSM }}
        />
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          disabled={loading}
          onClick={() => setCreateModalOpen(true)}
        >
          Neue Diagnose
        </Button>
      </div>
      <Table<Diagnose>
        rowKey={record => record.id}
        size="middle"
        showSorterTooltip={false}
        dataSource={filteredDiagnoses}
        pagination={{
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} bis ${range[1]} von ${total} Diagnosen`,
        }}
        loading={loading}
        columns={columns}
      />
      <CreateDiagnoseModal
        open={createModalOpen}
        onSuccess={() => refetch()}
        onClose={() => setCreateModalOpen(false)}
        labId={labId ?? ''}
        diagnoses={data?.labDiagnoses ?? []}
      />
      <UpdateDiagnoseModal
        diagnose={editDiagnose}
        onSuccess={() => refetch()}
        onClose={() => setEditDiagnose(null)}
        diagnoses={data?.labDiagnoses ?? []}
      />
    </MainContent>
  );
};
