import React, { Key, useCallback, useEffect, useState } from 'react';
import {
  App,
  Button,
  DatePicker,
  Dropdown,
  Input,
  Modal,
  Space,
  Table,
  TablePaginationConfig,
  Tooltip,
  Typography,
} from 'antd';
import { MainContent } from '../components/MainContent';
import { useQuery } from '@apollo/client';
import { ColumnsType, ColumnType } from 'antd/es/table';
import { Markable } from '../components/Markable';
import {
  EllipsisOutlined,
  FileTextOutlined,
  InfoCircleOutlined,
  PlusCircleOutlined,
  PrinterOutlined,
  RedoOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { API_DATE_FORMAT, SHORT_DATE_FORMAT, SHORT_DATETIME_FORMAT } from '../utils/dateFormatUtils';
import { RequestStatusBadge } from '../components/RequestStatusBadge';
import { RequestDetailsModal } from './request/RequestDetailsModal';
import { useDebouncedCallback } from 'use-debounce';
import { SorterResult } from 'antd/es/table/interface';
import { TableColumnSettings } from '../components/TableColumnSettings';
import { useNavigate } from 'react-router';
import { stripTypename } from '../utils/helpers';
import { PageHeader } from '@ant-design/pro-components';
import dayjs from 'dayjs';
import { tableActionCell, tableHoverPointer } from '../styles/globalCss';
import { ItemType } from 'antd/es/menu/interface';
import { usePrintEmptyLabel } from '../hooks/usePrintEmptyLabel';
import { useAppStore } from '../hooks/store/useAppStore';
import { useShallow } from 'zustand/react/shallow';
import { useRequestCopy } from '../hooks/useRequestCopy';
import { showParamWarning } from '../components/showParamWarning';
import { Forms } from './forms/Forms.tsx';
import { hasSomeRole, Role } from '../utils/user';
import { graphql } from '../graphql/generated';
import {
  OrderType,
  Pageable,
  PatientData,
  RequestFilter,
  SentRequestsQuery,
  SortOrder,
} from '../graphql/generated/graphql.ts';
import { useAuth } from 'react-oidc-context';
import { UpdateEvent } from '../socket/types.ts';
import { useSubscription } from '../socket/useSubscription.ts';
import { useCurrentContextStore } from '../hooks/store/useCurrentContextStore.ts';
import { TableColumnSetting } from '../hooks/store/useUserSettingsStore.ts';

const { RangePicker } = DatePicker;

export const SENT_REQUESTS_QUERY = graphql(`
  query SentRequests($doctorId: ID!, $pageable: Pageable!, $filter: RequestFilter!) {
    sentRequests(doctorId: $doctorId, pageable: $pageable, filter: $filter) {
      entries {
        id
        createdAt
        sentAt
        sampledAt
        status
        orders {
          id
          type
          number
          createdAt
          lab {
            id
            shortName
            identifier
          }
        }
        patientData {
          birthday
          city
          country
          email
          externalId
          firstName
          lastName
          gender
          zip
          svnr
          title
          street
          phone
          insuranceCode
          idCardAuthority
          idCardNumber
          idCardType
          insuranceCategory
          insuredPerson {
            phone
            street
            title
            svnr
            zip
            birthday
            gender
            firstName
            email
            country
            city
            lastName
          }
        }
        reorderable
        reorderableUntil
        createdByFirstName
        createdByLastName
        modifiedByFirstName
        modifiedByLastName
        sentByFirstName
        sentByLastName
      }
      totalCount
    }

    doctor(id: $doctorId) {
      id
      flipParamNames
    }
  }
`);

const defaultPageable: Pageable = {
  sortBy: 'sentAt',
  sortOrder: SortOrder.DESC,
  offset: 0,
  limit: 10,
};

export type Request = NonNullable<SentRequestsQuery['sentRequests']>['entries'][number];

export const SentRequests: React.FC = () => {
  const navigate = useNavigate();
  const { notification } = App.useApp();
  const { message } = App.useApp();
  const auth = useAuth();

  const redoAllowed = hasSomeRole(
    [Role.ROLE_LR_MEDCOM, Role.ROLE_LR_USER, Role.ROLE_LR_LAB_ADMIN, Role.ROLE_LR_FRONT_DESK],
    auth.user
  );
  const reorderAllowed = hasSomeRole(
    [Role.ROLE_LR_MEDCOM, Role.ROLE_LR_USER, Role.ROLE_LR_LAB_ADMIN, Role.ROLE_LR_FRONT_DESK],
    auth.user
  );

  const { setPatientData, setRequestIdForReorder, setRequestIdForNew, setExecuteRequestCopy } = useAppStore(
    useShallow(state => ({
      setPatientData: state.setPatientData,
      setRequestIdForReorder: state.setRequestIdForReorder,
      setRequestIdForNew: state.setRequestIdForNew,
      setExecuteRequestCopy: state.setExecuteRequestCopy,
    }))
  );
  const { currentDoctorId } = useCurrentContextStore();

  const [filter, setFilter] = useState<RequestFilter>({
    search: '',
    from: null,
    to: null,
    birthday: null,
  });
  const printEmptyLabel = usePrintEmptyLabel();
  const [pageable, setPageable] = useState<Pageable>(defaultPageable);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearch = useDebouncedCallback(value => setFilter({ ...filter, search: value }), 300);
  const [detailsRequestId, setDetailsRequestId] = useState<string | null>(null);
  const [formsPatientData, setFormsPatientData] = useState<PatientData | null>(null);

  const { data, previousData, loading, refetch } = useQuery(SENT_REQUESTS_QUERY, {
    variables: {
      doctorId: currentDoctorId,
      pageable: pageable,
      filter: filter,
    },
    fetchPolicy: 'cache-and-network',
  });

  const callback = useCallback(
    (event: UpdateEvent) => {
      console.debug('received UI update: ' + JSON.stringify(event));
      refetch();
    },
    [refetch]
  );

  useSubscription({ type: 'request-sent', tenantId: currentDoctorId }, callback);
  useSubscription({ type: 'order-received', tenantId: currentDoctorId }, callback);
  useSubscription({ type: 'request-reordered', tenantId: currentDoctorId }, callback);

  useRequestCopy('new', true, null, copyData => {
    if (copyData.unknownBillingParameters.length) {
      showParamWarning(
        notification,
        'Verrechnungssituation geändert',
        'Für folgende Parameter hat sich die Verrechnungssituation geändert:',
        copyData.unknownBillingParameters.map(it => (data?.doctor?.flipParamNames ? it.longName : it.shortName))
      );
    }
    if (copyData.unavailableParameters.length) {
      showParamWarning(
        notification,
        'Parameter nicht verfügbar',
        'Folgende Parameter stehen nicht zur Verfügung:',
        copyData.unavailableParameters
      );
    }
    navigate('/anforderung/parameterauswahl');
  });

  // reset pageable if state is invalid, e.g. when searching on last page
  useEffect(() => {
    if (
      data &&
      data.sentRequests &&
      data.sentRequests.totalCount > 0 &&
      pageable.offset >= data.sentRequests.totalCount
    ) {
      setPageable(defaultPageable);
    }
  }, [data, pageable.offset]);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    _: Record<string, (Key | boolean)[] | null>,
    sorter: SorterResult<Request> | SorterResult<Request>[]
  ) => {
    const currentPage = pagination.current || 1;
    const pageSize = pagination.pageSize || 10;

    setPageable({
      sortBy:
        (sorter as SorterResult<Request>).field
          ?.toString()
          .replace(',', '.')
          .replace('patientData', 'patient')
          .replace('createdAt', 'realCreatedAt') || 'sentAt',
      sortOrder: (sorter as SorterResult<Request>).order === 'descend' ? SortOrder.DESC : SortOrder.ASC,
      offset: (currentPage - 1) * pageSize,
      limit: pageSize,
    });
  };

  const [columnSettings, setColumnSettings] = useState<TableColumnSetting[]>([
    { id: 'status', visible: true, title: 'Status', checkable: false },
    { id: 'patientData.lastName', visible: true, title: 'Name', checkable: true },
    { id: 'createdAt', visible: false, title: 'Erstellt am', checkable: true },
    { id: 'sentAt', visible: true, title: 'Gesendet am', checkable: false },
    { id: 'patientData.svnr', visible: true, title: 'SVNR', checkable: true },
    { id: 'patientData.birthday', visible: true, title: 'Geburtstag', checkable: true },
    { id: 'sampledAt', visible: false, title: 'Probenentnahme am', checkable: true },
    { id: 'orders', visible: true, title: 'Auftrag', checkable: true },
    { id: 'createdByLastName', visible: false, title: 'Erstellt von', checkable: true },
    { id: 'modifiedByLastName', visible: false, title: 'Bearbeitet von', checkable: true },
    { id: 'sentByLastName', visible: false, title: 'Gesendet von', checkable: true },
  ]);

  const columns: ColumnsType<Request> = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      width: 100,
      ellipsis: true,
      render: value => <RequestStatusBadge status={value} />,
    },
    {
      title: 'Name',
      dataIndex: ['patientData', 'lastName'],
      key: 'patientData.lastName',
      sorter: true,
      width: 100,
      ellipsis: true,
      render: (_, record) => (
        <Typography.Text style={{ fontWeight: 500 }}>
          {record.patientData.title}{' '}
          <Markable tokens={filter.search ?? ''}>
            {record.patientData.firstName} {record.patientData.lastName}
          </Markable>
        </Typography.Text>
      ),
    },
    {
      title: 'Erstellt am',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      width: 100,
      ellipsis: true,
      render: value => dayjs(value).format(SHORT_DATETIME_FORMAT),
    },
    {
      title: 'Gesendet am',
      dataIndex: 'sentAt',
      key: 'sentAt',
      defaultSortOrder: 'descend',
      sorter: true,
      width: 100,
      ellipsis: true,
      render: value => dayjs(value).format(SHORT_DATETIME_FORMAT),
    },
    {
      title: 'SVNR',
      dataIndex: ['patientData', 'svnr'],
      key: 'patientData.svnr',
      sorter: true,
      width: 70,
      ellipsis: true,
      render: value => <Markable tokens={filter.search ?? ''}>{value}</Markable>,
    },
    {
      title: 'Geburtstag',
      dataIndex: ['patientData', 'birthday'],
      key: 'patientData.birthday',
      sorter: true,
      width: 120,
      ellipsis: true,
      render: value => dayjs(value).format(SHORT_DATE_FORMAT),
    },
    {
      title: 'Probenentnahme am',
      dataIndex: 'sampledAt',
      key: 'sampledAt',
      sorter: true,
      width: 180,
      ellipsis: true,
      render: value => dayjs(value).format(SHORT_DATETIME_FORMAT),
    },
    {
      title: 'Auftrag',
      dataIndex: 'orders',
      key: 'orders',
      width: 100,
      ellipsis: true,
      render: (_, record) =>
        [...record.orders]
          .sort(a => (a.type === OrderType.REORDER ? -1 : 1))
          .reduce<Request['orders']>(
            (orders, order) =>
              orders.some(o => o.number === order.number && o.lab.identifier === order.lab.identifier)
                ? orders
                : [...orders, order],
            []
          )
          .sort(
            (a, b) =>
              a.lab.id.localeCompare(b.lab.id) +
              a.number.localeCompare(b.number) +
              a.createdAt.localeCompare(b.createdAt)
          )
          .map((order, index, orders) => (
            <span key={order.id}>
              <Markable tokens={filter.search ?? ''}>{order.number}</Markable>{' '}
              {order.type === OrderType.REORDER && <PlusCircleOutlined />}{' '}
              <Typography.Text type="secondary">{order.lab.shortName}</Typography.Text>
              {orders.length - 1 > index ? ' - ' : ''}
            </span>
          )),
    },
    {
      title: 'Erstellt von',
      key: 'createdByLastName',
      sorter: true,
      width: 110,
      ellipsis: true,
      render: (_, record) => record.createdByFirstName + ' ' + record.createdByLastName,
    },
    {
      title: 'Bearbeitet von',
      key: 'modifiedByLastName',
      sorter: true,
      width: 140,
      ellipsis: true,
      render: (_, record) => record.modifiedByFirstName + ' ' + record.modifiedByLastName,
    },
    {
      title: 'Gesendet von',
      key: 'sentByLastName',
      sorter: true,
      width: 140,
      ellipsis: true,
      render: (_, record) => record.sentByFirstName + ' ' + record.sentByLastName,
    },
  ];

  const actionsColumn: ColumnType<Request> = {
    title: '',
    key: 'actions',
    fixed: 'right',
    align: 'right',
    ellipsis: true,
    width: '100px',
    className: tableActionCell,
    render: (_, record) => {
      const items: ItemType[] = [];
      if (window.nativeApi) {
        items.push({
          label: 'Leer-Etikett drucken',
          key: 'empty-label',
          icon: <PrinterOutlined />,
          onClick: info => {
            info.domEvent.stopPropagation();
            printEmptyLabel(
              {
                doctorId: currentDoctorId,
                patientTitle: record.patientData.title,
                patientLastName: record.patientData.lastName,
                patientFirstName: record.patientData.firstName,
                patientSvnr: record.patientData.svnr,
              },
              1,
              () => message.error('Beim Drucken ist ein Fehler aufgetreten'),
              () => message.success('Leer-Etikett wurde an den Drucker gesendet')
            );
          },
        });
      }
      items.push({
        label: 'Formulare',
        key: 'forms',
        icon: <FileTextOutlined />,
        onClick: info => {
          info.domEvent.stopPropagation();
          setFormsPatientData(record.patientData);
        },
      });
      if (redoAllowed) {
        items.push({
          label: 'Als Basis für Neue',
          key: 'redo-request',
          icon: <RedoOutlined />,
          onClick: info => {
            info.domEvent.stopPropagation();
            setRequestIdForNew(record.id);
            setExecuteRequestCopy(true);
          },
        });
      }

      return (
        <>
          {record.reorderable && reorderAllowed && (
            <Tooltip
              title={<span>bis {dayjs(record.reorderableUntil ?? undefined).format(SHORT_DATETIME_FORMAT)}</span>}
              placement="left"
            >
              <Button
                type="link"
                icon={<PlusCircleOutlined />}
                onClick={e => {
                  e.stopPropagation();
                  const patientData = stripTypename(record.patientData);
                  if (patientData.insuredPerson) {
                    patientData.insuredPerson = stripTypename(patientData.insuredPerson);
                  }
                  setRequestIdForReorder(record.id);
                  setPatientData(patientData);
                  navigate('/anforderung/parameterauswahl');
                }}
              >
                Nachforderung
              </Button>
            </Tooltip>
          )}
          <Dropdown menu={{ items: items }} trigger={['click']} placement="bottomRight">
            <Button
              icon={<EllipsisOutlined style={{ fontSize: '20px', verticalAlign: 'middle' }} />}
              type="text"
              onClick={e => e.stopPropagation()}
            />
          </Dropdown>
        </>
      );
    },
  };

  const filteredColumns: ColumnsType<Request> = [
    ...columnSettings
      .filter(cs => cs.visible)
      .map(cs => {
        return columns.find(c => c.key === cs.id)!;
      }),
    actionsColumn,
  ];

  return (
    <MainContent>
      <PageHeader
        title="Gesendete Anforderungen"
        style={{ padding: 0, paddingBottom: 'inherit' }}
        extra={
          <Space key="searchbar" direction="horizontal">
            <RangePicker
              allowEmpty={[true, true]}
              allowClear
              format={SHORT_DATE_FORMAT}
              presets={[
                { label: 'Heute', value: [dayjs(), dayjs()] },
                { label: 'Gestern', value: [dayjs().subtract(1, 'day'), dayjs().subtract(1, 'day')] },
                { label: 'Letzte 7 Tage', value: [dayjs().subtract(7, 'days'), dayjs()] },
                { label: 'Letzte 28 Tage', value: [dayjs().subtract(28, 'days'), dayjs()] },
                { label: 'Diese Woche', value: [dayjs().startOf('week'), dayjs()] },
                { label: 'Dieser Monat', value: [dayjs().startOf('month'), dayjs()] },
                { label: 'Dieses Jahr', value: [dayjs().startOf('year'), dayjs()] },
                { label: 'Gesamtzeit', value: [null, null] },
              ]}
              disabledDate={current => current.isAfter(dayjs())}
              onChange={dates =>
                setFilter({
                  ...filter,
                  from: dates && dates[0] ? dates[0].format(API_DATE_FORMAT) : null,
                  to: dates && dates[1] ? dates[1].format(API_DATE_FORMAT) : null,
                })
              }
            />
            <Input
              autoFocus
              allowClear
              placeholder="Suche"
              value={searchValue ?? ''}
              onChange={e => {
                setSearchValue(e.target.value);
                debouncedSearch(e.target.value);
              }}
              prefix={<SearchOutlined />}
              suffix={
                <Tooltip title="Suche nach Vorname, Nachname, Auftragsnummer oder SVNR">
                  <InfoCircleOutlined />
                </Tooltip>
              }
              style={{ width: '300px' }}
            />
            <DatePicker
              format={SHORT_DATE_FORMAT}
              placeholder="Geburtstag"
              allowClear
              onChange={date =>
                setFilter({
                  ...filter,
                  birthday: date ? date.format(API_DATE_FORMAT) : null,
                })
              }
              disabledDate={current =>
                current && (current.isAfter(dayjs()) || current.isBefore(dayjs().subtract(120, 'years')))
              }
            />
            <TableColumnSettings
              tableKey="sentRequests"
              initialColumnSettings={columnSettings}
              onChange={setColumnSettings}
            />
          </Space>
        }
      />
      <Table<Request>
        scroll={{ x: 'max-content' }}
        sticky
        showSorterTooltip={false}
        rowKey={record => record.id}
        size="middle"
        dataSource={data?.sentRequests?.entries ?? previousData?.sentRequests?.entries ?? []}
        pagination={{
          current: Math.floor(pageable.offset / pageable.limit + 1),
          pageSize: pageable.limit,
          total: data && data.sentRequests ? data.sentRequests.totalCount : 0,
          showTotal: total =>
            `${pageable.offset + 1} bis ${
              pageable.offset + pageable.limit > total ? total : pageable.offset + pageable.limit
            } von ${total} gesendeten Anforderungen`,
          showQuickJumper: true,
          showSizeChanger: true,
        }}
        onChange={(pagination, filters, sorter) => handleTableChange(pagination, filters, sorter)}
        loading={loading}
        columns={filteredColumns}
        onRow={record => ({
          onClick: () => {
            setDetailsRequestId(record.id);
          },
        })}
        rowClassName={tableHoverPointer}
      />
      <RequestDetailsModal requestId={detailsRequestId} onClose={() => setDetailsRequestId(null)} />
      <Modal
        title={`Formulare für ${formsPatientData?.firstName} ${formsPatientData?.lastName}`}
        width={768}
        open={!!formsPatientData}
        footer={null}
        onCancel={() => setFormsPatientData(null)}
        destroyOnClose
      >
        {formsPatientData && <Forms patientData={formsPatientData} />}
      </Modal>
    </MainContent>
  );
};
