import React from 'react';
import { Flex, theme, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { translateBillingType } from '../../utils/enumHelpers';
import { LgBadge } from '../../components/LgBadge';
import { currency } from '../../utils/currency';
import { BillingInfo, BillingType } from '../../graphql/generated/graphql.ts';
import { css } from '@emotion/css';

export const BillingInfoComponent: React.FC<{ billingInfo: BillingInfo; showPrice?: boolean }> = ({
  billingInfo,
  showPrice = true,
}) => {
  const { token } = theme.useToken();

  const spanStyle = css`
    line-height: 22px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  `;

  const flexStyle = css`
    overflow: hidden;
  `;

  switch (billingInfo.billingType) {
    case BillingType.SPECIAL_RATE:
      return (
        <span className={spanStyle}>
          {billingInfo.text} {showPrice && currency(billingInfo.price)}
        </span>
      );
    case BillingType.INSURANCE:
      return <span className={spanStyle}>{billingInfo.text}</span>;
    case BillingType.LG_INSURANCE:
      return (
        <Flex gap={token.marginXXS} align="center" className={flexStyle}>
          <LgBadge />
          <span className={spanStyle}>{billingInfo.text}</span>
        </Flex>
      );
    case BillingType.DIAGNOSE:
      return (
        <Tooltip title={billingInfo.text}>
          <Flex gap={token.marginXXS} align="center" className={flexStyle}>
            <InfoCircleOutlined />
            <span className={spanStyle}>Diagnose - {billingInfo.additionalText}</span>
          </Flex>
        </Tooltip>
      );
    case BillingType.LG_DIAGNOSE:
      return (
        <Tooltip title={billingInfo.text}>
          <Flex gap={token.marginXXS} align="center" className={flexStyle}>
            <LgBadge /> <InfoCircleOutlined />
            <span className={spanStyle}>Diagnose - {billingInfo.additionalText}</span>
          </Flex>
        </Tooltip>
      );
    case BillingType.LG_DOCTOR:
      return (
        <Flex gap={token.marginXXS} align="center" className={flexStyle}>
          <LgBadge />
          <span className={spanStyle}>Zuweiser {showPrice && currency(billingInfo.price)}</span>
        </Flex>
      );
    default:
      return (
        <span className={spanStyle}>
          {translateBillingType(billingInfo.billingType)} {showPrice && currency(billingInfo.price)}
        </span>
      );
  }
};
