import React, { useCallback, useState } from 'react';
import { Alert, Card, Col, Row, Select, Space, theme } from 'antd';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router';
import { AuditOutlined, PlusCircleOutlined, SendOutlined } from '@ant-design/icons';
import { DashboardEntry } from '../components/DashboardEntry';
import { DesktopVersionInfo } from './dashboard/DesktopVersionInfo';
import { DailyReport } from './dashboard/DailyReport';
import { graphql } from '../graphql/generated';
import { useSubscription } from '../socket/useSubscription.ts';
import { UpdateEvent } from '../socket/types.ts';
import { HelpSupport } from '../components/HelpSupport.tsx';
import { useCurrentContextStore } from '../hooks/store/useCurrentContextStore.ts';

const { useToken } = theme;

const DASHBOARD_DATA_QUERY = graphql(`
  query DashboardData($doctorId: ID!) {
    dashboardData(doctorId: $doctorId) {
      openRequests
      sentRequestsToday
      sentRequestsWeek
      sentRequestsMonth
      sentRequestsYear
    }
  }
`);

export const Dashboard: React.FC = () => {
  const { token } = useToken();
  const navigate = useNavigate();
  const { currentDoctorId } = useCurrentContextStore();
  const [range, setRange] = useState<'today' | 'week' | 'month' | 'year'>('today');

  const { data, loading, refetch } = useQuery(DASHBOARD_DATA_QUERY, {
    variables: {
      doctorId: currentDoctorId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const callback = useCallback(
    (event: UpdateEvent) => {
      console.debug('received UI update: ' + JSON.stringify(event));
      refetch();
    },
    [refetch]
  );

  useSubscription({ type: 'request-created', tenantId: currentDoctorId }, callback);
  useSubscription({ type: 'request-sent', tenantId: currentDoctorId }, callback);
  useSubscription({ type: 'request-deleted', tenantId: currentDoctorId }, callback);

  let sentRequests = 0;
  switch (range) {
    case 'today':
      sentRequests = data?.dashboardData?.sentRequestsToday ?? 0;
      break;
    case 'week':
      sentRequests = data?.dashboardData?.sentRequestsWeek ?? 0;
      break;
    case 'month':
      sentRequests = data?.dashboardData?.sentRequestsMonth ?? 0;
      break;
    case 'year':
      sentRequests = data?.dashboardData?.sentRequestsYear ?? 0;
      break;
  }

  const showOpenRequestsWarning = (data?.dashboardData?.openRequests ?? 0) > 300;

  return (
    <Space direction="vertical" size="middle" style={{ width: '100%', padding: token.paddingLG }}>
      {showOpenRequestsWarning ? (
        <Alert
          showIcon
          type="warning"
          message="Bitte offene Anforderungen senden oder aufräumen!"
          description="Es existieren mehr als 500 offene Anforderungen. Bitte senden Sie die relevanten Anforderungen, oder löschen Sie die nicht mehr benötigten offenen Anforderungen."
        />
      ) : null}
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={12} xl={8}>
          <DashboardEntry
            onClick={() => navigate('/anforderung')}
            title="Neue Anforderung erstellen"
            icon={PlusCircleOutlined}
            value=""
            loading={false}
            color={token.colorPrimary}
          />
        </Col>
        <Col xs={24} lg={12} xl={8}>
          <DashboardEntry
            onClick={() => navigate('/offene-anforderungen')}
            title="Offene Anforderungen"
            icon={AuditOutlined}
            value={data?.dashboardData?.openRequests || 0}
            loading={loading}
            color={token.colorWarning}
          />
        </Col>
        <Col xs={24} lg={12} xl={8}>
          <DashboardEntry
            onClick={() => navigate('/gesendete-anforderungen')}
            title="Gesendete Anforderungen"
            icon={SendOutlined}
            value={sentRequests}
            loading={loading}
            color={token.colorSuccess}
            extra={
              <Select
                size="small"
                value={range}
                onClick={e => e.stopPropagation()}
                style={{ width: '130px' }}
                onChange={value => setRange(value)}
              >
                <Select.Option value="today">Heute</Select.Option>
                <Select.Option value="week">Diese Woche</Select.Option>
                <Select.Option value="month">Dieses Monat</Select.Option>
                <Select.Option value="year">Dieses Jahr</Select.Option>
              </Select>
            }
          />
        </Col>
        <Col xs={24} lg={12} xl={8}>
          <Card title="Anforderungs-Übersicht" style={{ height: '100%' }}>
            <DailyReport doctorId={currentDoctorId} />
          </Card>
        </Col>
        <Col xs={24} lg={12} xl={8}>
          <Card title="Medcom Desktop" style={{ height: '100%' }}>
            <DesktopVersionInfo />
          </Card>
        </Col>
        <Col xs={24} lg={12} xl={8}>
          <Card title="Hilfe & Support" style={{ height: '100%' }}>
            <HelpSupport />
          </Card>
        </Col>
      </Row>
    </Space>
  );
};
