import React, { useEffect, useState } from 'react';
import { MainContent } from '../../../components/MainContent';
import { useParams } from 'react-router';
import { Button, Input, Table, theme, Tooltip } from 'antd';
import { useQuery } from '@apollo/client';
import { ColumnsType } from 'antd/es/table';
import { EditOutlined, InfoCircleOutlined, PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { useDebounce } from 'use-debounce';
import { Markable } from '../../../components/Markable';
import { CreateSpecialRateModal } from './specialRates/CreateSpecialRateModal';
import { UpdateSpecialRateModal } from './specialRates/UpdateSpecialRateModal';
import { tableActionCell } from '../../../styles/globalCss';
import { graphql } from '../../../graphql/generated';
import { SpecialRatesQuery } from '../../../graphql/generated/graphql.ts';

const { useToken } = theme;

export const SPECIAL_RATES_QUERY = graphql(`
  query SpecialRates($labId: ID!) {
    labSpecialRates(labId: $labId) {
      id
      name
      shortName
      code
    }
  }
`);

export type SpecialRate = NonNullable<SpecialRatesQuery['labSpecialRates']>[number];

export const SpecialRates: React.FC = () => {
  const { token } = useToken();
  const { id: labId } = useParams<{ id: string }>();
  const [search, setSearch] = useState<string | null>(null);
  const [debouncedSearch] = useDebounce(search, 250);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [editSpecialRate, setEditSpecialRate] = useState<SpecialRate | null>(null);
  const [filteredSpecialRates, setFilteredSpecialRates] = useState<SpecialRate[]>([]);

  const { data, loading, refetch } = useQuery(SPECIAL_RATES_QUERY, {
    variables: {
      labId: labId ?? '',
    },
    fetchPolicy: 'cache-and-network',
    pollInterval: 60000,
  });

  const columns: ColumnsType<SpecialRate> = [
    {
      title: 'Kurzbezeichnung',
      dataIndex: 'shortName',
      key: 'shortName',
      ellipsis: true,
      width: 150,
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.shortName.localeCompare(b.shortName),
      render: value => <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>,
    },
    {
      title: 'Bezeichnung',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: value => <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>,
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      ellipsis: true,
      width: 100,
      sorter: (a, b) => a.code.localeCompare(b.code),
      render: value => <Markable tokens={debouncedSearch ?? ''}>{value}</Markable>,
    },
    {
      title: '',
      key: 'actions',
      fixed: 'right',
      align: 'right',
      ellipsis: true,
      width: '50px',
      className: tableActionCell,
      render: (_, record) => <Button icon={<EditOutlined />} type="text" onClick={() => setEditSpecialRate(record)} />,
    },
  ];

  useEffect(() => {
    const filtered = data?.labSpecialRates?.filter(s => {
      if (debouncedSearch && debouncedSearch.length) {
        const searchValue = debouncedSearch.toLowerCase();
        return (
          s.name.toLowerCase().includes(searchValue) ||
          s.shortName.toLowerCase().includes(searchValue) ||
          s.code.toLowerCase().includes(searchValue)
        );
      }
      return true;
    });
    setFilteredSpecialRates(filtered ?? []);
  }, [debouncedSearch, data]);

  return (
    <MainContent>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: token.paddingSM }}>
        <Input
          allowClear
          autoFocus
          placeholder="Suche"
          value={search ?? ''}
          onChange={e => setSearch(e.target.value)}
          prefix={<SearchOutlined />}
          suffix={
            <Tooltip title="Suche nach Kurzbezeichnung, Bezeichnung oder Code">
              <InfoCircleOutlined />
            </Tooltip>
          }
          style={{ width: '300px', marginRight: token.paddingSM }}
        />
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          disabled={loading}
          onClick={() => setCreateModalVisible(true)}
        >
          Neuer Sondertarif / Zuweiserkasse
        </Button>
      </div>
      <Table<SpecialRate>
        scroll={{ x: 'max-content' }}
        rowKey={record => record.id}
        size="middle"
        showSorterTooltip={false}
        dataSource={filteredSpecialRates}
        pagination={{
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} bis ${range[1]} von ${total} Sondertarifen`,
        }}
        loading={loading}
        columns={columns}
      />
      <CreateSpecialRateModal
        modalVisible={createModalVisible}
        onSuccess={() => refetch()}
        onClose={() => setCreateModalVisible(false)}
        labId={labId ?? ''}
        specialRates={data?.labSpecialRates ?? []}
      />
      <UpdateSpecialRateModal
        specialRate={editSpecialRate}
        onSuccess={() => refetch()}
        onClose={() => setEditSpecialRate(null)}
        specialRates={data?.labSpecialRates ?? []}
      />
    </MainContent>
  );
};
